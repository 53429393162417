import Grid from '@mui/material/Grid';
import { FormikListPage } from '@silinfo/front-end-template';
import ExportButton from '../../../components/Buttons/ExportButton';
import SemesterSelector from '../../../components/SemesterSelector';
import { SemesterContextProvider } from '../../../components/SemesterSelector/SemesterContextProvider';
import courseEvaluationReportService from '../../../services/courseManagement/courseEvaluationReport';
import { Option } from '../../../utils/AppConst';
import { endpoints } from '../../../utils/endPoints';
import { IForm } from '../../../utils/Interfaces/interfaces';
import { filterFields, filterFieldsStudents } from './fields';
import { TabContentProps } from './types';
import { columns, exportContext, header } from './utils';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import CachedIcon from '@mui/icons-material/Cached';
import API from '../../../api';
import { create } from '../../../store/notification';
import { useState } from 'react';
import MCCLoading from '../../../components/MCCLoading';
import { useTenant } from '../../../components/TenantContext';

export default function TabContent({ view }: TabContentProps) {
    const { user } = useSelector((state: RootState) => state.auth);
    const [semester, setSemester] = useState<Option | null>(null);
    const [formikFilter, setFormikFilter] = useState({});
    const [syncing, setSyncing] = useState(false);
    const tenant = useTenant();
    const headerWithTenant = header(tenant || 'TAR');
    const filterFunc = (filter: IForm) => {
        setFormikFilter(filter);
        return courseEvaluationReportService[view === 'course' ? 'filterCourses' : 'filterStudents'](
            semester?.value || '',
        )(filter);
    };
    const dispatch = useDispatch();

    const handleSync = () => {
        setSyncing(true);
        API.get(
            endpoints.courseManagement.courseEvaluationReport.studentsSync.replace(
                '{semesterId}',
                semester?.value ?? '',
            ),
        )
            .then(() => {
                dispatch(create({ type: 'success', message: 'Sikeres CENTRAL sync!' }));
            })
            .catch((error) => {
                console.error(error);
                dispatch(create({ type: 'error', message: 'Sikertelen CENTRAL sync!' }));
            })
            .finally(() => {
                setSyncing(false);
            });
    };

    return (
        <>
            {syncing && <MCCLoading />}
            <Grid item xs={12}>
                <Grid container alignItems="center" justifyContent="space-between">
                    <Grid item xs>
                        <SemesterSelector
                            semesterState={[semester, setSemester]}
                            url={endpoints.selfEvaluation.report.semesters}
                            defaultActive
                        />
                    </Grid>
                    {view === 'student' && (
                        <Grid item marginTop="4px" marginLeft="12px">
                            <Tooltip title="Kérdőív értekek szinkronizálása az adott szemeszterhez">
                                <IconButton onClick={handleSync} disabled={syncing}>
                                    <CachedIcon />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    )}
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <SemesterContextProvider semester={semester?.value || ''}>
                    <FormikListPage
                        header={{ ...headerWithTenant, breadcrumbs: {} }}
                        autoload
                        service={{
                            filter: filterFunc,
                        }}
                        customButtons={
                            <>
                                {!user.archive && (
                                    <Grid item xs={12} textAlign="right">
                                        <ExportButton
                                            exportContext={exportContext(formikFilter, semester?.value || '', view)}
                                        />
                                    </Grid>
                                )}
                            </>
                        }
                        columns={columns(view, user.archive)}
                        responseKey="data"
                        filter={{
                            fields:
                                view === 'course'
                                    ? filterFields(semester?.value || '', view)
                                    : filterFieldsStudents(semester?.value || '', view),
                        }}
                        refresh={semester?.value || ''}
                    />
                </SemesterContextProvider>
            </Grid>
        </>
    );
}
