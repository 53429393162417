// eslint-disable-next-line regex/invalid-warn
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';

export const tableColumns = (
    renderCell: (row: GridRenderCellParams) => JSX.Element,
    campusCellRenderer: (row: GridRenderCellParams) => JSX.Element,
    trainingProgramCellRenderer: (row: GridRenderCellParams) => JSX.Element,
    statusCellRenderer: (row: GridRenderCellParams) => JSX.Element,
): GridColDef[] =>
    [
        {
            headerName: 'Tenant',
            field: 'tenant',
        },
        {
            headerName: 'Hallgató neve',
            sortable: false,
            field: 'student',
        },
        {
            headerName: 'Campus',
            field: 'campus',
            renderCell: campusCellRenderer,
            sortable: false,
        },
        {
            headerName: 'Képzési program',
            field: 'trainingProgram',
            renderCell: trainingProgramCellRenderer,
            sortable: false,
        },
        {
            headerName: 'Jelentkezés dátuma',
            field: 'enrollmentDate',
            sortable: false,
            minWidth: 150,
        },
        {
            headerName: 'Státusz',
            field: 'status',
            renderCell: statusCellRenderer,
            sortable: false,
            minWidth: 150,
        },
        {
            headerName: 'Műveletek',
            field: 'operations',
            renderCell,
            sortable: false,
            minWidth: 100,
        },
    ].map((col) => ({ flex: 1, minWidth: 250, ...col }));
