import { GridAlignment, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { Input, Option } from '@silinfo/front-end-template/lib/esm/components/Form';
import { Dispatch, SetStateAction } from 'react';
import OperationsCellRenderer from './OperationsCellRenderer';

export const SESSION_KEY = 'documents';

export const header = (tenant: string | null) => {
    return {
        breadcrumbs: {
            'administration': 'Adminisztráció',
            'documents': 'Dokumentumok',
        },
        title: 'Dokumentumok',
        project: tenant || 'TAR',
    };
};

export const filterFields = (options: Record<string, Option[]>): Input[] => {
    return [
        {
            name: 'name',
            label: 'Dokumentum neve',
        },
        {
            name: 'campus',
            label: 'Campus',
            type: 'multiselect',
            options: options.campuses,
            fieldType: 'base',
        },
        {
            name: 'trainingLevel',
            label: 'Képzési szint',
            type: 'multiselect',
            options: options.trainingLevels,
            fieldType: 'base',
        },
        {
            name: 'trainingProgram',
            label: 'Képzési program',
            type: 'multiselect',
            fieldType: 'base',
            options: options.trainingPrograms,
            props: {
                renderOption: (props: object, option: Option) => {
                    const { label } = option;
                    if (label.includes('(archív)')) {
                        return (
                            <li {...props} style={{ color: '#999' }}>
                                {option.label}
                            </li>
                        );
                    } else {
                        return <li {...props}>{option.label}</li>;
                    }
                },
            },
        },
        {
            name: 'semester',
            label: 'Szemeszter',
            type: 'multiselect',
            fieldType: 'base',
            options: options.semesters,
        },
        {
            name: 'updatedAtFrom',
            label: 'Utolsó módosítás ideje (-tól)',
            type: 'datetime-local',
        },
        {
            name: 'updatedAtTo',
            label: 'Utolsó módosítás ideje (-ig)',
            type: 'datetime-local',
        },
        {
            name: 'isEnrollmentDocument',
            label: 'Beiratkozási dokumentum',
            type: 'multiselect',
            options: options.isEnrollmentDocument,
            fieldType: 'base',
        },
    ];
};

export const columns = (setRefresh: Dispatch<SetStateAction<boolean>>): GridColDef[] => {
    const cols: GridColDef[] = [
        {
            field: 'name',
            headerName: 'Dokumentum neve',
            sortable: true,
        },
        {
            field: 'campus',
            headerName: 'Campus',
            sortable: true,
        },
        {
            field: 'trainingLevels',
            headerName: 'Képzési szint',
            sortable: true,
        },
        {
            field: 'trainingPrograms',
            headerName: 'Képzési program',
            sortable: true,
        },
        {
            field: 'semester',
            headerName: 'Szemeszter',
            sortable: true,
        },
        {
            field: 'updatedAt',
            headerName: 'Utolsó módosítás ideje',
            sortable: true,
        },
        {
            field: 'isEnrollmentDocument',
            headerName: 'Beiratkozási dokumentum',
            valueGetter: (params) => (params.value ? 'Igen' : 'Nem'),
            sortComparator: (v1, v2, cellParams1, cellParams2) => {
                const val1 = cellParams1.value ? 1 : 0;
                const val2 = cellParams2.value ? 1 : 0;
                return val1 - val2;
            },
            filterable: true,
            sortable: true,
        },
        {
            field: 'operations',
            headerName: 'Műveletek',
            renderCell: (params: GridRenderCellParams) => (
                <OperationsCellRenderer setRefresh={setRefresh} {...params} />
            ),
            align: 'center' as GridAlignment,
            minWidth: 110,
        },
    ];

    return cols.map((column) => ({
        flex: 1,
        minWidth: 150,
        headerAlign: 'center',
        sortable: false,
        ...column,
    }));
};

const autoload = true as const;

export const tableProps = (
    options: Record<string, Option[]>,
    refresh: boolean,
    setRefresh: Dispatch<SetStateAction<boolean>>,
    archive?: boolean,
    tenant?: string,
) => ({
    autoload,
    header: header(tenant || 'TAR'),
    columns: columns(setRefresh),
    filter: { fields: filterFields(options) },
    refresh: refresh,
    defaultSort: { name: 'asc' as const },
    responseKey: 'data',
    ...(archive ? {} : { newLabel: 'Új dokumentum hozzáadása' }),
    tableProps: {
        columnBuffer: columns(setRefresh).length,
        disableSelectionOnClick: true,
    },
    sessionKey: SESSION_KEY,
});
