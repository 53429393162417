import API from '../../api';
import { destructArrayFields, fixDateRangeFields } from '../../utils/AppConst';
import { endpoints } from '../../utils/endPoints';
import { IForm } from '../../utils/Interfaces/interfaces';

const courseEvaluationReportService = {
    filterCourses: (semesterId: number | string) => (form: IForm) =>
        API.get(endpoints.courseManagement.courseEvaluationReport.courses.replace('{semesterId}', `${semesterId}`), {
            params: destructArrayFields(fixDateRangeFields(form)),
        }),
    filterStudents: (semesterId: number | string) => (form: IForm) =>
        API.get(endpoints.courseManagement.courseEvaluationReport.students.replace('{semesterId}', `${semesterId}`), {
            params: destructArrayFields(fixDateRangeFields(form)),
        }),
    courseSearch: (semesterId: number | string) => (term: string) =>
        API.get(
            endpoints.courseManagement.courseEvaluationReport.courseSearch.replace('{semesterId}', `${semesterId}`) +
                '?term=' +
                term,
        ),
    studentSearch: (semesterId: number | string) => (term: string) =>
        API.get(
            endpoints.courseManagement.courseEvaluationReport.studentSearch.replace('{semesterId}', `${semesterId}`) +
                '?term=' +
                term,
        ),
    questionnaireFillsCourses: (courseId: number | string) =>
        API.get(
            endpoints.courseManagement.courseEvaluationReport.questionnaireFillsCourses.replace(
                '{courseId}',
                `${courseId}`,
            ),
        ),
    questionnaireFillsCoursesWithTenant: (courseId: number | string, tenantId: number | string) =>
        API.get(
            endpoints.courseManagement.courseEvaluationReport.questionnaireFillsCoursesWithTenant
                .replace('{tenantId}', `${tenantId}`)
                .replace('{courseId}', `${courseId}`),
        ),
    questionnaireFillsStudents: (semesterId: number | string, studentId: number | string) =>
        API.get(
            endpoints.courseManagement.courseEvaluationReport.questionnaireFillsStudents
                .replace('{semesterId}', `${semesterId}`)
                .replace('{studentId}', `${studentId}`),
        ),

    /**
     * Export
     *
     */
    initiateExport: (courseId: number | string) => (form: { format: string }, filter: IForm) =>
        API.post(
            endpoints.courseManagement.courseEvaluationReport.export.initiateExport.replace(
                '{courseId}',
                `${courseId}`,
            ),
            form,
            { params: filter },
        ),
    initiateExportWithTenant:
        (courseId: number | string, tenantId: string) => (form: { format: string }, filter: IForm) =>
            API.post(
                endpoints.courseManagement.courseEvaluationReport.export.initiateExportWithTenant
                    .replace('{courseId}', `${courseId}`)
                    .replace('{tenantId}', `${tenantId}`),
                form,
                { params: filter },
            ),

    checkExport: (courseId: number | string) => (exportId: number) =>
        API.get(
            endpoints.courseManagement.courseEvaluationReport.export.checkExport
                .replace('{courseId}', `${courseId}`)
                .replace('{id}', `${exportId}`),
        ),
    downloadExport: (courseId: number | string) => (exportId: number) =>
        API.get(
            endpoints.courseManagement.courseEvaluationReport.export.downloadExport
                .replace('{courseId}', `${courseId}`)
                .replace('{id}', `${exportId}`),
            {
                responseType: 'arraybuffer',
            },
        ),
    initiateBySemesterExport: (semesterId: number | string) => (form: { format: string }, filter: IForm) =>
        API.post(
            endpoints.courseManagement.courseEvaluationReport.export.initiateBySemesterExport.replace(
                '{semesterId}',
                `${semesterId}`,
            ),
            form,
            { params: filter },
        ),
    checkBySemesterExport: (semesterId: number | string, view: string) => (exportId: number) =>
        API.get(
            endpoints.courseManagement.courseEvaluationReport.export.checkBySemesterExport
                .replace('{semesterId}', `${semesterId}`)
                .replace('{id}', `${exportId}`)
                .replace('{exportType}', view),
        ),
    downloadBySemesterExport: (semesterId: number | string, view: string) => (exportId: number) =>
        API.get(
            endpoints.courseManagement.courseEvaluationReport.export.downloadBySemesterExport
                .replace('{semesterId}', `${semesterId}`)
                .replace('{id}', `${exportId}`)
                .replace('{exportType}', view),
            {
                responseType: 'arraybuffer',
            },
        ),
};

export default courseEvaluationReportService;
