import { IMultiFormRenderFormParams } from './types';
import { useDispatch } from 'react-redux';
import { multiformReadonlyRules, useFormPreviewStyles } from './utils';
import { useState } from 'react';
import { create } from '../../store/notification';
import InformationPanel from '@silinfo/form-builder-2/lib/esm/components/FormPreview/InformationPanel';
import { ReadonlyContextProvider } from '@silinfo/form-builder-2/lib/esm/components/FormPreview/ReadonlyContext';
import { FormContextProvider } from '@silinfo/form-builder-2/lib/esm/components/FormPreview/FormContext';
import { FileContextProvider } from '@silinfo/form-builder-2/lib/esm/components/FormPreview/FileContext';
import Panel from '@silinfo/form-builder-2/lib/esm/components/FormPreview/Panel';
import { Actions } from '@silinfo/form-builder-2/lib/esm/components/FormPreview/Actions';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { fileProps } from '../../utils/AppConst';
import { FileProps } from '@silinfo/form-builder-2/lib/esm/components/FormPreview/types';
import { withStyles } from '@mui/styles';
import { Theme } from '@mui/system';

const StyledSaveButton = withStyles((theme: Theme) => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        marginRight: '10px',
    },
}))(Button);

export function MultiFormRenderForm({
    dateFilled,
    setDateFilled,
    formToSet,
    setForm,
    form,
    formIndex,
    onSubmit,
    setTab,
    groupDateFilled,
    rules,
}: IMultiFormRenderFormParams) {
    const dispatch = useDispatch();
    const classes = useFormPreviewStyles();
    const pages = Object.values(form.pages || {});
    const count = pages.length + 1;
    const [index, setIndex] = useState(0);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const page = pages[index];
    const panels = Object.values(page?.panels || {});
    const [serverError, setServerError] = useState('');

    const customOnSave = async () => {
        setLoading(true);
        try {
            await onSubmit(formToSet, formIndex, false);
            dispatch(create({ type: 'success', message: 'Sikeres mentés!' }));
        } catch (e) {
            dispatch(create({ type: 'error', message: 'Sikertelen mentés!' }));
        } finally {
            setLoading(false);
        }
    };

    const customOnFinalize = (form: Record<string, unknown>): Promise<unknown> => {
        return onSubmit(form, formIndex, true).then(() => {
            if (formIndex === 0) {
                setDateFilled(true);
                setTab('1');
            }
            dispatch(create({ type: 'success', message: 'Sikeres véglegesítés!' }));
        });
    };

    const readonlyRules = multiformReadonlyRules(
        index === pages.length - 1,
        dateFilled,
        !!groupDateFilled,
        formIndex,
        rules,
    );

    return (
        <div className={readonlyRules.readonly ? classes.readableEnabled : classes.readableDisabled}>
            {serverError ? (
                <InformationPanel message={serverError} />
            ) : (
                <ReadonlyContextProvider readonly={!!readonlyRules.readonly}>
                    <FileContextProvider file={fileProps as FileProps<unknown>}>
                        <FormContextProvider formState={[formToSet, setForm, errors, setErrors]}>
                            {index === count - 1 ? (
                                <InformationPanel message={'Sikeres mentés!'} />
                            ) : (
                                panels.map((panel) => <Panel key={panel.ID} panel={panel} />)
                            )}
                        </FormContextProvider>
                    </FileContextProvider>
                </ReadonlyContextProvider>
            )}

            <Actions
                index={index}
                count={count}
                pages={pages}
                onSubmit={customOnFinalize}
                formToSet={formToSet}
                setIndex={setIndex}
                setErrors={setErrors}
                readonly={!readonlyRules.showFinalizeButton}
                setServerError={setServerError}
                error={false}
                OtherButton={
                    readonlyRules.showSaveButton && (
                        <StyledSaveButton
                            variant="contained"
                            onClick={customOnSave}
                            color="warning"
                            startIcon={loading ? <CircularProgress color="info" size={20} /> : null}
                            disabled={loading}
                        >
                            Mentés
                        </StyledSaveButton>
                    )
                }
            />
        </div>
    );
}
