import Grid from '@mui/material/Grid';
import { PaperProps } from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { Paper } from '../Paper';
import { IPerformance } from './Performance';
import { PerformanceIndicatorProps } from './PerformanceIndicator';
import CheckCircle from '@mui/icons-material/CheckCircle';
import Feed from '@mui/icons-material/Feed';
import NotificationsActive from '@mui/icons-material/NotificationsActive';
import { ICourseView } from '../../../pages/CourseManagement/Courses/types';

export const uniformStyle: Partial<PaperProps> = {
    style: { minWidth: '40px', textAlign: 'center', marginRight: '4px' },
};

export const uniformStyleWithLeftMargin: Partial<PaperProps> = {
    style: { minWidth: '40px', textAlign: 'center', marginRight: '4px', marginLeft: '4px' },
};

export const titles: string[] = [
    'Fix kurzuspont',
    'Egyéni elbírálású pont',
    'Eseményenként fixált pont',
    'Eseményenként egyéni elbírálású pont',
];

export interface ITitlesAndBodyData {
    titles: string[];
    bodyData: JSX.Element[][];
}

export const getBodyData: (props: ICourseView) => JSX.Element[][] = (props: ICourseView) => {
    const isCopper = props.scoringType === 'copper';
    return [
        [
            <Grid item container xs={12} alignItems="center" justifyContent="space-between" spacing={1} key={1}>
                <Grid item xs={2}>
                    <Paper {...uniformStyle}>{props.point.fixCoursePointVal}</Paper>
                </Grid>
                <Grid item xs={9}>
                    {isCopper ? 'garas' : 'pont'} a kurzus teljesítéséért
                </Grid>
            </Grid>,
            <Typography key={11}>eddig megszerzett {isCopper ? 'garasok' : 'pontok'}</Typography>,
            <Grid item container key={111} xs={12} alignItems="center" justifyContent="space-between" spacing={1}>
                <Grid item xs={2}>
                    <Paper {...uniformStyle}>{props.totalPointsOnCourse.fixPoints}</Paper>
                </Grid>
                <Grid item xs={9}>
                    {isCopper ? 'garas' : 'pont'}
                </Grid>
            </Grid>,
        ],
        [
            <Grid item container xs={12} alignItems="center" justifyContent="space-between" spacing={1} key={2}>
                <Grid item xs={2}>
                    min.
                </Grid>
                <Grid item xs={2}>
                    <Paper {...uniformStyle}>{props.point.customCoursePointValMin}</Paper>
                </Grid>
                <Grid item xs={7}>
                    {isCopper ? 'garas' : 'pont'}
                </Grid>
                <Grid item xs={2}>
                    max.
                </Grid>
                <Grid item xs={2}>
                    <Paper {...uniformStyle}>{props.point.customCoursePointValMax}</Paper>
                </Grid>
                <Grid item xs={7}>
                    {isCopper ? 'garas' : 'pont'}
                </Grid>
            </Grid>,
            <Typography key={22}>kapott {isCopper ? 'garasok' : 'pontszám'}</Typography>,
            <Grid item container key={222} xs={12} alignItems="center" justifyContent="space-between" spacing={1}>
                <Grid item xs={2}>
                    <Paper {...uniformStyle}>{props.totalPointsOnCourse.customPoints}</Paper>
                </Grid>
                <Grid item xs={9}>
                    {isCopper ? 'garas' : 'pont'}
                </Grid>
            </Grid>,
        ],
        [
            <Grid item container xs={12} alignItems="center" justifyContent="space-between" spacing={1} key={3}>
                <Grid item xs={2}>
                    min.
                </Grid>
                <Grid item xs={2}>
                    <Paper {...uniformStyle}>{props.point.fixedCoursePointPerEventValMin}</Paper>
                </Grid>
                <Grid item xs={7}>
                    eseményen történő részvétel
                </Grid>
                <Grid item xs={4}>
                    minden esemény
                </Grid>
                <Grid item xs={2}>
                    <Paper {...uniformStyle}>{props.point.fixedCoursePointPerEventValPoint}</Paper>
                </Grid>
                <Grid item xs={5}>
                    {isCopper ? 'garas' : 'pont'}
                </Grid>
            </Grid>,
            <Grid item container key={33} xs={12} justifyContent="space-between" alignItems="center" spacing={1}>
                <Grid item xs={2}>
                    <Paper {...uniformStyle}>{props.presence}</Paper>
                </Grid>
                <Grid item xs={9}>
                    eseményen részt vett
                </Grid>
            </Grid>,
            <Grid item container key={333} xs={12} justifyContent="space-between" alignItems="center" spacing={1}>
                <Grid item xs={4}>
                    összesen
                </Grid>
                <Grid item xs={2}>
                    <Paper {...uniformStyle}>{props.totalPointsOnCourse.fixEventPoints}</Paper>
                </Grid>
                <Grid item xs={6}>
                    {isCopper ? 'garas' : 'pont'}
                </Grid>
            </Grid>,
        ],
        [
            <Grid item container xs={12} alignItems="center" justifyContent="space-between" spacing={1} key={4}>
                <Grid item xs={6} md={1}>
                    min.
                </Grid>
                <Grid item xs={6} md={2}>
                    <Paper {...uniformStyleWithLeftMargin}>{props.totalPointsOnCourse.totalMinCustomEventPoints}</Paper>
                </Grid>
                <Grid item xs={6} md={1}>
                    max.
                </Grid>
                <Grid item xs={6} md={2}>
                    <Paper {...uniformStyleWithLeftMargin}>{props.totalPointsOnCourse.totalMaxCustomEventPoints}</Paper>
                </Grid>
                <Grid item xs={12} md={6}>
                    {isCopper ? 'garas' : 'pont'} az eseményeknél.
                </Grid>
            </Grid>,
            <Typography key={44}>Eddig megszerzett {isCopper ? 'garasok' : 'pontok'}</Typography>,
            <Grid item container key={444} justifyContent="space-between" xs={12} alignItems="center" spacing={1}>
                <Grid item xs={2}>
                    <Paper {...uniformStyle}>{props.totalPointsOnCourse.customEventPoints}</Paper>
                </Grid>
            </Grid>,
        ],
    ];
};

export const performanceData = (data: IPerformance): PerformanceIndicatorProps[] => [
    {
        color: 'error',
        label: 'Min. teljesítendő',
        value: data.minToBeCompleted,
        ratio: data.minToBeCompleted ? (data.completed.point / data.minToBeCompleted) * 100 : 0,
        Icon: NotificationsActive,
    },
    {
        color: 'info',
        label: 'Felvett kurzusok',
        value:
            data.enrolledMin.point != data.enrolledMax.point
                ? [data.enrolledMin.point, data.enrolledMax.point]
                : data.enrolledMin.point,
        subValue:
            data.enrolledMin.copper != data.enrolledMax.copper
                ? [data.enrolledMin.copper, data.enrolledMax.copper]
                : data.enrolledMin.copper,
        ratio: data.minToBeCompleted ? (data.enrolledMin.point / data.minToBeCompleted) * 100 : 0,
        Icon: Feed,
    },
    {
        color: 'success',
        label: 'Teljesített kurzusok',
        value: data.completed.point,
        subValue: data.completed.copper,
        ratio: data.enrolledMax.point ? (data.completed.point / data.enrolledMax.point) * 100 : 0,
        Icon: CheckCircle,
    },
];

export const getTitlesAndBodyData: (props: ICourseView) => ITitlesAndBodyData = (props: ICourseView) => {
    const titlesTmp: string[] = [];
    const bodyDataTmp: JSX.Element[][] = [];

    const bodyDataArray = getBodyData(props);

    if (props.point.fixCoursePoint) {
        titlesTmp.push(titles[0]);
        bodyDataTmp.push(bodyDataArray[0]);
    }
    if (props.point.customCoursePoint) {
        titlesTmp.push(titles[1]);
        bodyDataTmp.push(bodyDataArray[1]);
    }
    if (props.point.fixedCoursePointPerEvent) {
        titlesTmp.push(titles[2]);
        bodyDataTmp.push(bodyDataArray[2]);
    }
    if (props.point.customCoursePointPerEvent) {
        titlesTmp.push(titles[3]);
        bodyDataTmp.push(bodyDataArray[3]);
    }

    return { titles: titlesTmp, bodyData: bodyDataTmp };
};
