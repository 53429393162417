import Grid from '@mui/material/Grid';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { FormikListPage, TableDeleteButton, TableEditButton } from '@silinfo/front-end-template';
import { AxiosError } from 'axios';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import MCCLoading from '../../../../../components/MCCLoading';
import administrationFeesService, {
    IAdministrationFeeValType,
} from '../../../../../services/masterData/finances/administrationFees/administrationFees';
import administrationFeeValuesService from '../../../../../services/masterData/finances/administrationFees/administrationFeeValues/administrationFeeValues';
import { RootState } from '../../../../../store';
import { create } from '../../../../../store/notification';
import { TooltipRenderer } from '../../../../../utils/AppConstElements';
import { clientEndPoints } from '../../../../../utils/clientEndPoints';
import { IForm } from '../../../../../utils/Interfaces/interfaces';
import api from '../../../../../api';
import { endpoints } from '../../../../../utils/endPoints';
import { useTenant } from '../../../../../components/TenantContext';

export default function SemesterFees() {
    const { user } = useSelector((state: RootState) => state.auth);
    const [loading, setLoading] = useState(false);
    const { feeId } = useParams() as { feeId: string };
    const dispatch = useDispatch();
    const [feeName, setFeeName] = useState('');
    const [currency, setCurrency] = useState<string>('');
    const tenant = useTenant();

    useEffect(() => {
        administrationFeesService.get(feeId).then((res) => setFeeName(res.data.type));
    }, [feeId, dispatch]);

    useEffect(() => {
        api.get(endpoints.currentTenantAll)
            .then((response) => {
                setCurrency(response.data.currencyTrans);
            })
            .catch((error) => {
                console.error('Error fetching current tenant currency:', error);
            });
    }, []);

    const header = {
        project: tenant || 'TAR',
        title: 'Adminisztrációs díjak szemeszterenként',
        breadcrumbs: {
            masterData: 'Törzsadatok',
            [clientEndPoints.md_finance]: 'Pénzügy',
            [clientEndPoints.md_finance_administration_fees_list]: feeName + ' díj',
            [clientEndPoints.md_finance_administration_fees_semesters_list.replace(':feeId', feeId)]:
                'Adminisztrációs díjak szemeszterenként',
        },
    };

    const columns = [
        {
            headerName: 'Szemeszter',
            field: 'semester',
            minWidth: 300,
            renderCell: (params: GridRenderCellParams<string, IAdministrationFeeValType>) =>
                TooltipRenderer(
                    params.row.semester.name +
                        ' - ' +
                        params.row.semester.processName +
                        ' (' +
                        moment(params.row.semester.startDate).toDate().toLocaleDateString('hu') +
                        ' - ' +
                        moment(params.row.semester.endDate).toDate().toLocaleDateString('hu') +
                        ')',
                ),
            sortable: false,
        },
        {
            headerName: 'Összeg',
            field: 'amount',
            renderCell: (params: GridRenderCellParams<string, IAdministrationFeeValType>) =>
                TooltipRenderer(params.row.amount + ' ' + currency),
        },
        {
            headerName: 'Műveletek',
            field: 'operations',
            renderCell: (params: GridRenderCellParams<string, IAdministrationFeeValType>) =>
                !user.archive && (
                    <Grid container spacing={1}>
                        <Grid item>
                            <TableEditButton target={params.row.id + '/edit'} />
                        </Grid>
                        <Grid item>
                            <TableDeleteButton
                                deleteAction={() => {
                                    setLoading(true);
                                    administrationFeeValuesService
                                        .delete(params.row.id)
                                        .then(() => {
                                            dispatch(create({ type: 'success', message: 'Sikeres törlés' }));
                                        })
                                        .catch((error: AxiosError) => {
                                            dispatch(
                                                create({
                                                    type: 'error',
                                                    message: error.response?.data?.detail || 'Sikertelen törlés',
                                                }),
                                            );
                                        })
                                        .finally(() => setLoading(false));
                                }}
                                confirmationElement={params.row.semester.name}
                            />
                        </Grid>
                    </Grid>
                ),
            sortable: false,
        },
    ];

    if (loading) return <MCCLoading />;

    return (
        <FormikListPage
            newLabel={!user.archive ? 'Adminisztrációs díj hozzárendelése szemeszterhez' : ''}
            autoload
            header={header}
            service={{ filter: (form: IForm) => administrationFeeValuesService.list(form, feeId) }}
            columns={columns.map((column) => ({ flex: 1, ...column }))}
            defaultSort={{ amount: 'asc' }}
        />
    );
}
