import Visibility from '@mui/icons-material/Visibility';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { TableButton, TableEditButton } from '@silinfo/front-end-template';
import EndpointProvider from '../../../components/EndpointProvider';
import { handleAdminPreviewOpen } from '../../../utils/AppConst';
import { clientEndPoints } from '../../../utils/clientEndPoints';
import ArchivateButton from './ArchivateButton';
import DeleteButton from './DeleteButton';
import DuplicateButton from './DuplicateButton';
import UnarchivateButton from './UnarchivateButton';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';

type BtnCellRendererProps = {
    params: GridRenderCellParams;
    refresh: () => void;
};

export function BtnCellRenderer({ params, refresh }: BtnCellRendererProps) {
    const { user } = useSelector((state: RootState) => state.auth);
    const used = params.row.status == 'used' ? true : false;
    const archive = params.row.archive;
    const handlePreviewClick = handleAdminPreviewOpen(params.row.id);

    return (
        <>
            {!user.archive && (
                <EndpointProvider
                    endpoints={[
                        'self_evaluation_edit',
                        'admin_questionnaire_templates_edit',
                        'admin_questionnaire_groups_edit',
                    ]}
                >
                    <TableEditButton
                        disabled={used}
                        tooltip="Szerkesztés"
                        sx={{ marginRight: '5px' }}
                        color="warning"
                        target={clientEndPoints.admin_questionnaire_templates_edit.replace(':id', '' + params.id)}
                    />
                    <DuplicateButton id={params.row.id} refresh={refresh} />
                </EndpointProvider>
            )}
            {!user.archive && (
                <EndpointProvider
                    endpoints={[
                        'admin_questionnaire_templates_preview',
                        'admin_questionnaire_template_groups_preview',
                        'self_evaluation_edit',
                    ]}
                >
                    <TableButton tooltip={'Előnézet'} onClick={handlePreviewClick} sx={{ marginRight: '5px' }}>
                        <Visibility />
                    </TableButton>
                </EndpointProvider>
            )}
            {!user.archive && (
                <EndpointProvider
                    endpoints={[
                        'self_evaluation_edit',
                        'admin_questionnaire_templates_edit',
                        'admin_questionnaire_groups_edit',
                    ]}
                >
                    {archive ? (
                        <UnarchivateButton id={params.row.id} refresh={refresh} archive={archive} />
                    ) : (
                        <ArchivateButton id={params.row.id} refresh={refresh} archive={archive} />
                    )}
                    {!used && <DeleteButton id={params.row.id} refresh={refresh} />}
                </EndpointProvider>
            )}
        </>
    );
}
