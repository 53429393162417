import Grid from '@mui/material/Grid';
import { GridColDef, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid-pro';
import { FormikListPage, Link, TableButton, TableDeleteButton, TableEditButton } from '@silinfo/front-end-template';
import { useDispatch, useSelector } from 'react-redux';
import courseTypesService, { ICourseType, TScoringType } from '../../../../services/masterData/courses/courseTypes';
import { create } from '../../../../store/notification';
import { filterFields as fields } from './fields';
import { useCallback, useState } from 'react';
import MCCLoading from '../../../../components/MCCLoading';
import { clientEndPoints } from '../../../../utils/clientEndPoints';
import { RootState } from '../../../../store';
import Visibility from '@mui/icons-material/Visibility';
import { scoringTypeLabel } from '../../../../utils/AppConst';
import { useTenant } from '../../../../components/TenantContext';
export default function CourseTypes() {
    const { user } = useSelector((state: RootState) => state.auth);
    const [loading, setLoading] = useState(false);
    const tenant = useTenant();

    const header = {
        project: tenant || 'TAR',
        title: 'Kurzustípusok',
        breadcrumbs: {
            masterData: 'Törzsadatok',
            [clientEndPoints.md_courses]: 'Kurzusok',
            courseTypes: 'Kurzustípusok',
        },
    };

    const filter = {
        fields,
    };

    const dispatch = useDispatch();

    const handleDelete = useCallback(
        (params: GridRenderCellParams<string, ICourseType>) => {
            setLoading(true);
            courseTypesService
                .delete(params.id)
                .then(() => {
                    dispatch(create({ type: 'success', message: 'Sikeres törlés' }));
                })
                .catch(() => dispatch(create({ type: 'error', message: 'Sikertelen törlés' })))
                .finally(() => setLoading(false));
        },
        [dispatch],
    );

    const columns: GridColDef[] = [
        { headerName: 'Név', field: 'name', minWidth: 240 },
        { headerName: 'Rövidítés', field: 'shortName' },
        {
            headerName: 'Kurzuskategória',
            field: 'courseCategory',
            valueGetter: (params: GridValueGetterParams<string, ICourseType>) =>
                params.row.courseCategory === 'conventional' ? 'Konvencionális' : 'Nem konvencionális',
        },
        {
            headerName: 'Aktív',
            field: 'active',
            valueGetter: (params: GridValueGetterParams<string, ICourseType>) => (params.row.active ? 'Igen' : 'Nem'),
            sortComparator: (a, b) => {
                if (a > b) return -1;
                else if (a < b) return 1;
                else return 0;
            },
        },
        {
            headerName: 'Pontozás típusa',
            field: 'scoringType',
            valueGetter: (params: GridValueGetterParams<TScoringType, ICourseType>) =>
                params.value ? scoringTypeLabel(params.value) : '-',
        },
        {
            headerName: 'Rendezvénynaptár esemény hozzárendelhető',
            field: 'eventAssignable',
            valueGetter: (params: GridValueGetterParams<string, ICourseType>) =>
                params.row.eventAssignable ? 'Igen' : 'Nem',
            sortComparator: (a, b) => {
                if (a > b) return -1;
                else if (a < b) return 1;
                else return 0;
            },
        },
        {
            headerName: 'Műveletek',
            field: 'operations',
            renderCell: (params: GridRenderCellParams<string, ICourseType>) =>
                !user.archive && params.row.name !== 'Megosztott' ? (
                    <Grid container spacing={1}>
                        <Grid item>
                            <TableEditButton target={params.id + '/edit'} />
                        </Grid>
                        <Grid item>
                            {params.row.coursesCount === 0 &&
                                params.row.trainingProgramRequirementCourseTypesCount === 0 && (
                                    <TableDeleteButton
                                        deleteAction={() => handleDelete(params)}
                                        confirmationMessage={
                                            "Biztosan törli a(z) '" +
                                            params.row.name +
                                            "' kurzustípust a rendszerből? Be nem állított követelmények tartozhatnak a kurzustípushoz, amennyiben törli a kurzust, abban az esetben a hozzá tartozó, be nem állított követelmények is törlődni fognak."
                                        }
                                    />
                                )}
                        </Grid>
                    </Grid>
                ) : (
                    <Link to={clientEndPoints.md_courses_course_types_show.replace(':id', params.id.toString())}>
                        <TableButton tooltip={'Adatok megtekintése'}>
                            <Visibility />
                        </TableButton>
                    </Link>
                ),
            sortable: false,
        },
    ];

    if (loading) return <MCCLoading />;

    return (
        <FormikListPage
            autoload
            header={header}
            service={courseTypesService}
            columns={columns.map((column) => ({ minWidth: 150, flex: 1, ...column }))}
            defaultSort={{ name: 'asc' }}
            newLabel={!user.archive ? 'Új kurzustípus hozzáadása' : ''}
            filter={filter}
            responseKey="data"
        />
    );
}
