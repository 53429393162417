import DownloadIcon from '@mui/icons-material/Download';
import { LoadingButton } from '@mui/lab';
import { AxiosError, AxiosResponse } from 'axios';
import { Dispatch, SetStateAction, useState } from 'react';
import { useDispatch } from 'react-redux';
import { create, remove } from '../../../store/notification';
import { downloadFile } from '../../../utils/AppConst';
import { IInfo } from '../../../utils/Interfaces/IInfo';
import { IForm } from '../../../utils/Interfaces/interfaces';

export interface ExportContext {
    initiateExport: (form: { format: string }, filter: IForm) => Promise<AxiosResponse>;
    checkExport: (id: number) => Promise<AxiosResponse>;
    downloadExport: (id: number) => Promise<AxiosResponse>;
    info?: IInfo<IForm>;
    filter: IForm;
}

export type ExportButtonProps = {
    exportContext: ExportContext;
    title?: string;
    otherLoading?: boolean;
    setOtherLoading?: Dispatch<SetStateAction<boolean>>;
    setPageLoading?: Dispatch<SetStateAction<boolean>>;
    isEmpty?: boolean;
};

export function createExportHandleClick(
    exportContext: ExportContext,
    dispatch: Dispatch<unknown>,
    setIsLoading: Dispatch<SetStateAction<boolean>>,
    setPageLoading?: Dispatch<SetStateAction<boolean>>,
    setOtherLoading?: Dispatch<SetStateAction<boolean>>,
) {
    return () => {
        const toSendForm = {
            format: 'xlsx',
        };

        dispatch(create({ type: 'info', message: 'A fájl előállítása folyamatban...' }));
        const searchForm = exportContext.filter;
        setIsLoading(true);
        if (setOtherLoading) {
            setOtherLoading(true);
        }
        if (setPageLoading) {
            setPageLoading(true);
        }
        exportContext
            .initiateExport(toSendForm, searchForm)
            .then((data: AxiosResponse) => {
                const id = data.data.id;
                let ready = false;
                let isError = false;
                let fileName = '';

                const interval = setInterval(() => {
                    exportContext
                        .checkExport(id)
                        .then((d: AxiosResponse) => {
                            const status = d.data.status;
                            fileName = d.data.fileName;
                            ready = status === 'finished' || status === 'unknown_error';
                            isError = status !== 'finished';
                        })
                        .catch((error: AxiosError) => {
                            console.log(error, 'hiba');
                            if (setPageLoading) {
                                setPageLoading(false);
                            }
                        });
                    if (ready) {
                        clearInterval(interval);
                        if (!isError) {
                            exportContext
                                .downloadExport(id)
                                .then((response: AxiosResponse) => {
                                    downloadFile(response.data, fileName);
                                    dispatch(remove());
                                    dispatch(create({ type: 'success', message: `A fájl sikeresen előállítva!` }));
                                    setIsLoading(false);
                                    if (setOtherLoading) {
                                        setOtherLoading(false);
                                    }
                                })
                                .catch(() => {
                                    dispatch(remove());
                                    dispatch(
                                        create({ type: 'error', message: `Hiba történt a fájl előállítása során` }),
                                    );
                                })
                                .finally(() => {
                                    if (setPageLoading) {
                                        setPageLoading(false);
                                    }
                                });
                        } else {
                            dispatch(remove());
                            dispatch(create({ type: 'error', message: `Hiba történt a fájl előállítása során` }));
                        }
                    }
                }, 2000);
            })
            .catch((error: AxiosError) => console.log(error));
    };
}

export default function ExportButton(props: ExportButtonProps): JSX.Element {
    const { exportContext, setPageLoading, otherLoading, setOtherLoading } = props;
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const dispatch = useDispatch();

    return (
        <LoadingButton
            variant="contained"
            disabled={isLoading || otherLoading || props.isEmpty}
            loading={isLoading}
            loadingPosition="start"
            onClick={createExportHandleClick(exportContext, dispatch, setIsLoading, setPageLoading, setOtherLoading)}
            startIcon={<DownloadIcon />}
        >
            {props.title || 'Exportálás'}
        </LoadingButton>
    );
}
