import DialogBasic from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Theme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import { CancelButton, SubmitButton } from '@silinfo/front-end-template';
import React, { useState } from 'react';
import RequestSendButton from './RequestSendButton';
import courseEnrollmentService from '../../services/studentServices/courseEnrollment';
import { IPrerequisites } from './types';
import PrerequisiteModal from './PrerequisiteModal';

const useStyles = makeStyles((theme: Theme) => ({
    title: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
    },
}));

export default function RequestButton({
    opener,
    courseId,
    courseName,
    courseCode,
    type,
    refresh,
    courseTenant,
    externalCourseId,
}: {
    opener: JSX.Element;
    courseId: string;
    courseName: string;
    courseCode: string;
    type: string;
    refresh: () => void;
    courseTenant: string | null;
    externalCourseId?: string;
}) {
    const [openPreModal, setOpenPreModal] = useState(false);
    const [prerequisites, setPrerequisites] = React.useState<IPrerequisites[]>([]);
    const [open, setOpen] = useState(false);

    const classes = useStyles();
    const handleOpen = () => {
        if (prerequisites.length > 0) {
            setOpenPreModal(true);
        } else {
            setOpen(true);
        }
    };

    const handleClose = () => {
        setOpen(false);
        refresh();
    };

    useState(() => {
        if (type != 'dismiss') {
            courseEnrollmentService.prerequisites(courseId, courseTenant).then((ret) => {
                if (!ret.data.success) {
                    setPrerequisites(ret.data.prerequisites);
                }
            });
        }
    });

    const msg =
        type === 'enroll'
            ? 'Biztosan kérelmezed a tárgy időszakon túli felvételét?'
            : 'Biztosan kérelmezed a tárgy időszakon túli leadását?';

    const msgDesc =
        type === 'enroll'
            ? 'A kurzusfelvétel kurzusfelvételi időszakon túl történik. Ez a művelet adminisztrációs díjjal jár. Biztosan kérvényezed a kurzus felvételét? (A kérvény beadását követően adminisztrátor jóváhagyása szükséges, melyről a későbbiekben fogsz értesítést kapni.)'
            : 'A kurzusleadás kurzusfelvételi időszakon túl történik. Ez a művelet adminisztrációs díjjal jár. Biztosan kérvényezed a kurzus leadását? (A kérvény beadását követően adminisztrátor jóváhagyása szükséges, melyről a későbbiekben fogsz értesítést kapni.)';

    return (
        <>
            {React.cloneElement(opener, {
                onClick: () => {
                    handleOpen();
                },
            })}
            <DialogBasic open={open} onClose={handleClose} fullWidth>
                <DialogTitle className={classes.title}>Figyelem</DialogTitle>
                <DialogContent style={{ paddingTop: '24px' }}>
                    <Grid item xs={12}>
                        {msg}
                    </Grid>
                    <Grid item xs={12}>
                        {msgDesc}
                    </Grid>
                </DialogContent>

                <DialogActions>
                    <RequestSendButton
                        type={type}
                        courseId={courseId}
                        externalCourseId={externalCourseId}
                        opener={<SubmitButton>Igen</SubmitButton>}
                        handleCloseParent={handleClose}
                        courseTenant={courseTenant}
                    />
                    <CancelButton onClick={handleClose}>Nem</CancelButton>
                </DialogActions>
            </DialogBasic>
            <PrerequisiteModal
                open={openPreModal}
                courseName={courseName}
                courseCode={courseCode}
                prerequisites={prerequisites}
                handleClose={() => setOpenPreModal(false)}
            />
        </>
    );
}
