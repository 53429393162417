import { FormikListPage } from '@silinfo/front-end-template';
import { useState } from 'react';
import MCCLoading from '../../../components/MCCLoading';
import documentService from '../../../services/administration/document';
import useOptions from './useOptions';
import { tableProps } from './utils';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { useTenant } from '../../../components/TenantContext';

export default function Documents() {
    const [refresh, setRefresh] = useState(false);
    const { options, loading } = useOptions();
    const { user } = useSelector((state: RootState) => state.auth);
    const tenant = useTenant();
    if (loading) return <MCCLoading />;

    return (
        <FormikListPage {...tableProps(options, refresh, setRefresh, user.archive, tenant)} service={documentService} />
    );
}
