import { GridColumns } from '@mui/x-data-grid';
import { ExportContext } from '../../../components/Buttons/ExportButton/index';
import Operations from './Operations';
import { CourseEvaluationView, ICourseEvaluationReport } from './types';
import AnonymousCellRenderer from './AnonymousCellRenderer';
import courseEvaluationReportService from '../../../services/courseManagement/courseEvaluationReport';
import { Option } from '@silinfo/front-end-template';
import Tooltip from '@mui/material/Tooltip';

const countColumnsCourses: GridColumns<ICourseEvaluationReport> = [
    {
        field: 'questionnairesFilled',
        headerName: 'Kitöltött kérdőívek',
    },
    {
        field: 'questionnairesNotFilled',
        headerName: 'Kitöltetlen kérdőívek',
    },
    {
        field: 'questionnairesDeclined',
        headerName: 'Elutasított kitöltések',
    },
    {
        field: 'questionnairesExpired',
        headerName: 'Lejárt határidejű kérdőívek',
    },
];

const countColumnsStudents: GridColumns<ICourseEvaluationReport> = [
    {
        field: 'filled',
        headerName: 'Kitöltött kérdőívek',
    },
    {
        field: 'unfilled',
        headerName: 'Kitöltetlen kérdőívek',
    },
    {
        field: 'declined',
        headerName: 'Elutasított kitöltések',
    },
    {
        field: 'expired',
        headerName: 'Lejárt határidejű kérdőívek',
    },
];

const courseColumns: GridColumns<ICourseEvaluationReport> = [
    {
        headerName: '',
        field: 'indicator',
        sortable: false,
        resizable: false,
        align: 'left' as const,
        flex: 0,
        minWidth: 3,
        width: 3,
        renderCell: (params) => {
            if (params.row?.sharedType !== true) {
                return <></>;
            }

            return (
                <Tooltip title={'Megosztott kurzus'}>
                    <div style={{ background: '#35A899', width: '10px', height: '100%', marginLeft: '-10px' }} />
                </Tooltip>
            );
        },
    },
    {
        field: 'course',
        headerName: 'Kurzus',
        align: 'left' as const,
        flex: 3,
        minWidth: 300,
    },
    { field: 'anonymous', headerName: 'Anonimitás', renderCell: AnonymousCellRenderer },
    { field: 'studentCount', headerName: 'Hallgatók száma' },
];

const studentColumns: GridColumns<ICourseEvaluationReport> = [
    {
        field: 'student',
        headerName: 'Hallgató',
        align: 'left' as const,
        flex: 3,
        minWidth: 300,
    },
    { field: 'campus', headerName: 'Campus' },
    { field: 'trainingProgram', headerName: 'Képzési program' },
];

export const columns = (view: CourseEvaluationView, archive?: boolean): GridColumns<ICourseEvaluationReport> =>
    [
        ...(view === 'course' ? courseColumns : studentColumns),
        ...(view === 'course' ? countColumnsCourses : countColumnsStudents),
        ...(!archive
            ? [
                  {
                      field: 'operations',
                      headerName: 'Műveletek',
                      renderCell: Operations,
                      sortable: false,
                  },
              ]
            : []),
    ].map((column) => ({
        flex: 1,
        minWidth: 150,
        headerAlign: 'center',
        align: 'center',
        valueGetter: (params) => params.value || '-',
        ...column,
    }));

export const header = (tenant: string | null) => {
    return {
        title: 'Kurzusértékelési riport',
        project: tenant || 'TAR',
        breadcrumbs: {
            courseManagement: 'Kurzuskezelés',
            courseEvaluationReport: 'Kurzusértékelési riport',
        },
    };
};

export const exportContext = (
    filter: Record<string, Option[] | unknown>,
    semesterId: number | string,
    view: CourseEvaluationView,
): ExportContext => {
    filter.campus && Array.isArray(filter.campus)
        ? (filter.campus = filter.campus.map((element: Option) => element.value))
        : '';
    filter.trainingProgram && Array.isArray(filter.trainingProgram)
        ? (filter.trainingProgram = filter.trainingProgram.map((element: Option) => element.value))
        : '';
    filter.trainingLevel && Array.isArray(filter.trainingLevel)
        ? (filter.trainingLevel = filter.trainingLevel.map((element: Option) => element.value))
        : '';
    filter.collegeYear && Array.isArray(filter.collegeYear)
        ? (filter.collegeYear = filter.collegeYear.map((element: Option) => element.value))
        : '';

    filter.exportType = view;

    return {
        initiateExport: courseEvaluationReportService.initiateBySemesterExport(semesterId),
        checkExport: courseEvaluationReportService.checkBySemesterExport(semesterId, view),
        downloadExport: courseEvaluationReportService.downloadBySemesterExport(semesterId, view),
        filter: filter,
    };
};

export const ROLES = {
    ADMIN: 'ROLE_SUPERADMIN',
    USER: 'ROLE_USER_FUNCTIONAL',
    TRAINING_PROGRAM_LEADER: 'ROLE_TRAINING_PROGRAM_LEADER',
    TRAINING_PROGRAM_ADMIN: 'ROLE_TRAINING_PROGRAM_ADMIN',
    EP_COORDINATOR: 'ROLE_EP_COORDINATOR',
    FINANCIAL_ADMIN: 'ROLE_FINANCIAL_ADMIN',
    APPROVER_DECISION_MAKER: 'ROLE_APPROVER_DECISION_MAKER',
    OPERATOR: 'ROLE_OPERATOR',
    BOARD: 'ROLE_BOARD',
    STUDENT: 'ROLE_STUDENT',
};

export const ROLES_TO_CHECK = {
    TEACHER: 'ROLE_TEACHER',
    COURSE_MANAGER: 'ROLE_COURSE_MANAGER',
};
