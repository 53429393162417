import { Input } from '@silinfo/front-end-template';

export const scoringTypeOptions = [
    { value: 'copper', label: 'Garas' },
    { value: 'point', label: 'Pont' },
];

export const filterFields: Input[] = [
    {
        name: 'name',
        label: 'Megnevezés',
        format: {
            xs: 12,
            md: 8,
        },
    },
    {
        name: 'shortName',
        label: 'Rövidítés',
        format: {
            xs: 12,
            md: 4,
        },
        props: {
            inputProps: {
                maxLength: 3,
            },
        },
    },
    {
        name: 'courseCategory',
        label: 'Kurzuskategória',
        type: 'multiselect',
        options: [
            { value: 'conventional', label: 'Konvencionális' },
            { value: 'unconventional', label: 'Nem konvencionális' },
        ],
        format: {
            xs: 12,
            md: 4,
        },
    },
    {
        name: 'active',
        label: 'Aktív',
        type: 'multiselect',
        options: [
            { value: '1', label: 'Igen' },
            { value: '0', label: 'Nem' },
        ],
        format: {
            xs: 12,
            md: 2,
        },
    },
    {
        name: 'scoringType',
        label: 'Pontozás típusa',
        type: 'multiselect',
        options: scoringTypeOptions,
        format: {
            xs: 12,
            md: 2,
        },
    },
    {
        name: 'eventAssignable',
        label: 'Rendezvénynaptár esemény hozzárendelhető',
        type: 'multiselect',
        options: [
            { value: '1', label: 'Igen' },
            { value: '0', label: 'Nem' },
        ],
        format: {
            xs: 12,
            md: 2,
        },
    },
];

export const formFields: Input[] = [
    {
        name: 'name',
        label: 'Megnevezés',
        format: {
            xs: 12,
            md: 8,
        },
    },
    {
        name: 'shortName',
        label: 'Rövidítés',
        format: {
            xs: 12,
            md: 4,
        },
        props: {
            inputProps: {
                maxLength: 3,
            },
        },
    },
    {
        name: 'courseCategory',
        label: 'Kurzuskategória',
        type: 'select',
        options: [
            { value: 'conventional', label: 'Konvencionális' },
            { value: 'unconventional', label: 'Nem konvencionális' },
        ],
        format: {
            xs: 12,
            md: 4,
        },
    },
    {
        name: 'active',
        label: 'Aktív',
        type: 'select',
        options: [
            { value: 'true', label: 'Igen' },
            { value: 'false', label: 'Nem' },
        ],
        format: {
            xs: 12,
            md: 2,
        },
    },
    {
        name: 'scoringType',
        label: 'Pontozás típusa',
        type: 'select',
        options: scoringTypeOptions,
        format: {
            xs: 12,
            md: 2,
        },
    },
    {
        name: 'eventAssignable',
        label: 'Rendezvénynaptár esemény hozzárendelhető',
        type: 'select',
        options: [
            { value: 'true', label: 'Igen' },
            { value: 'false', label: 'Nem' },
        ],
        format: {
            xs: 12,
            md: 2,
        },
    },
];
