import Button from '@mui/material/Button';
import { ChipProps } from '@mui/material/Chip';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { Loading } from '@silinfo/front-end-template';
import { useCallback, useEffect, useState } from 'react';
import courseEnrollmentService from '../../services/studentServices/courseEnrollment';
import { EnrolledCountCellRenderer } from '../../utils/AppConstElements';

interface IEnrolledStudents {
    fullName: string;
}

type HeadCountCellRendererProps =
    | { Opener: (openerProps: ChipProps) => JSX.Element; id: string; params?: never }
    | {
          params: GridRenderCellParams;
          Opener?: never;
          id?: never;
      };

export default function HeadCountCellRenderer({ params, Opener, id }: HeadCountCellRendererProps) {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = useCallback(() => setOpen(false), [setOpen]);
    const [enrolledStudents, setEnrolledStudents] = useState<IEnrolledStudents[]>([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (open) {
            setLoading(true);
            courseEnrollmentService
                .getEnrolledStudentsNames(id || params?.row.externalId, params?.row.courseTenant)
                .then((res) => {
                    setEnrolledStudents(res.data);
                    setLoading(false);
                });
        }
    }, [open, id, params?.row.externalId, params?.row.courseTenant]);

    return (
        <>
            {Opener ? (
                <Opener onClick={handleOpen} />
            ) : (
                <Button color="info" onClick={handleOpen}>
                    {EnrolledCountCellRenderer(
                        params.row.headCount - params.row.waitingListCount,
                        params.row.maxHeadCount,
                        params.row.waitingListCount,
                    )}
                </Button>
            )}
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Beiratkozott hallgatók</DialogTitle>
                {loading ? (
                    <Loading noHeight />
                ) : (
                    <DialogContent dividers>
                        {enrolledStudents.length > 0 ? (
                            <>
                                <Table>
                                    <TableBody>
                                        {enrolledStudents.map((student) => (
                                            <TableRow key={student.fullName}>
                                                <TableCell align={'center'}>{student.fullName}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </>
                        ) : (
                            <i>Nincs beiratkozott hallgató</i>
                        )}
                    </DialogContent>
                )}
                <DialogActions>
                    <Button variant="outlined" onClick={handleClose}>
                        Bezár
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
