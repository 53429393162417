import { default as Visibility } from '@mui/icons-material/Visibility';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { Link, TableButton, TableDeleteButton, TableEditButton } from '@silinfo/front-end-template';
import { Dispatch, SetStateAction } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import EndpointProvider from '../../../components/EndpointProvider';
import courseListService from '../../../services/courseManagement/courseList';
import { RootState } from '../../../store';
import { create } from '../../../store/notification';
import { clientEndPoints } from '../../../utils/clientEndPoints';
import useAccessCheck from '../../../utils/useAccessCheck';
import CourseActionButton from './CourseActionButton';
import RosterButton from './RosterButton';

export function BtnCellRenderer(params: GridRenderCellParams, setLoading: Dispatch<SetStateAction<boolean>>) {
    const dispatch = useDispatch();
    const { user } = useSelector((state: RootState) => state.auth);
    const editable = params.row.accessOwn;
    const hasAccessToBaseEdit = useAccessCheck(['course_manamagement_course_edit_base']);

    return (
        <>
            <RosterButton {...params} />
            {!user.archive && editable && !params.row.closed ? (
                <TableEditButton
                    tooltip={hasAccessToBaseEdit ? 'Szerkesztés' : 'Részleges szerkesztés'}
                    sx={{ marginRight: '5px' }}
                    target={clientEndPoints.course_manamagement_course_edit.replace(':courseId', params.row.id)}
                    color="warning"
                />
            ) : (
                <Link
                    to={
                        clientEndPoints.course_manamagement_course_show.replace(':courseId', params.row.id) +
                        (params.row.isGuest ? '?courseTenant=' + params.row.courseTenant : '')
                    }
                >
                    <TableButton
                        sx={{ marginRight: '5px' }}
                        tooltip={params.row.closed ? 'Lezárt kurzus megtekintése ' : 'Adatok megtekintése'}
                    >
                        <Visibility />
                    </TableButton>
                </Link>
            )}
            <EndpointProvider endpoints={['close_course']}>
                {!user.archive ? <CourseActionButton {...params} setLoading={setLoading} /> : <></>}
            </EndpointProvider>
            {!params.row.hasStudents && !params.row.closed && !user.archive && params.row.deleteable && (
                <TableDeleteButton
                    tooltip={'Törlés'}
                    deleteAction={() => {
                        setLoading(true);
                        courseListService
                            .delete(params.row.id)
                            .then(() =>
                                dispatch(
                                    create({
                                        type: 'success',
                                        message: 'Sikeres törlés',
                                    }),
                                ),
                            )
                            .catch(() =>
                                dispatch(
                                    create({
                                        type: 'error',
                                        message: 'Sikertelen törlés',
                                    }),
                                ),
                            )
                            .finally(() => setLoading(false));
                    }}
                    confirmationElement={params.row.courseName}
                    sx={{ marginRight: '5px' }}
                />
            )}
        </>
    );
}
