import Checkbox from '@mui/material/Checkbox';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { TableEditButton } from '@silinfo/front-end-template';
import { clientEndPoints } from '../../../utils/clientEndPoints';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import NotificationsOffIcon from '@mui/icons-material/NotificationsOff';
import IconButton from '@mui/material/IconButton';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Tooltip from '@mui/material/Tooltip';
import notificationsService from '../../../services/administration/notifications';
import { Dispatch, SetStateAction } from 'react';
import { create } from '../../../store/notification';

export const CheckboxCellRenderer = (props: GridRenderCellParams<{ value: boolean }>) => {
    return (
        <Checkbox
            checked={props.value?.value}
            // Gyakorlatilag a disabled megoldása, csak szebb verzióban (hogy ne szürkén jelenjen meg):
            disableRipple
            onClick={(e) => {
                e.preventDefault();
            }}
            style={{
                cursor: 'default',
            }}
        />
    );
};

export const EditCellRenderer = (props: GridRenderCellParams, setRefresh: Dispatch<SetStateAction<boolean>>) => {
    const { user } = useSelector((state: RootState) => state.auth);
    const dispatch = useDispatch();

    const submitActivation = (
        eventId: number | string,
        active: boolean,
        setRefresh: Dispatch<SetStateAction<boolean>>,
    ) => {
        setRefresh(true);
        notificationsService
            .activation(eventId, active)
            .then(() => {
                setRefresh(false);
                dispatch(create({ type: 'success', message: 'Sikeres mentés' }));
            })
            .catch(() => dispatch(create({ type: 'error', message: 'Sikertelen mentés' })));
    };

    return !user.archive ? (
        <>
            <TableEditButton
                target={clientEndPoints.admin_notifications_edit.replace(':id', props.row.notificationEventId + '')}
            />
            {!props.row.disabled ? (
                props.row.active ? (
                    <Tooltip title="Értesítés deaktiválása">
                        <IconButton
                            onClick={() => submitActivation(props.row.notificationEventId, false, setRefresh)}
                            color={'info'}
                            sx={{ marginLeft: '5px' }}
                        >
                            <NotificationsOffIcon />
                        </IconButton>
                    </Tooltip>
                ) : (
                    <Tooltip title="Értesítés aktiválása">
                        <IconButton
                            onClick={() => submitActivation(props.row.notificationEventId, true, setRefresh)}
                            color={'info'}
                            sx={{ marginLeft: '5px' }}
                        >
                            <NotificationsIcon />
                        </IconButton>
                    </Tooltip>
                )
            ) : (
                <></>
            )}
        </>
    ) : (
        <>-</>
    );
};
