import { FormPreview, IForm } from '@silinfo/form-builder-2';
import { useFetch } from '@silinfo/front-end-template';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import MCCLoading from '../../../../components/MCCLoading';
import Logo from '../../../../images/MCC_logo_for_form.png';
import { RootState } from '../../../../store';
import { fileProps, formFilledSuccessfullyMessage } from '../../../../utils/AppConst';
import { endpoints } from '../../../../utils/endPoints';

const dummyAsyncFunction = async () => {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve('OK');
        }, 1000);
    });
};

export default function TemplatePreview() {
    const { user } = useSelector((state: RootState) => state.auth);
    const [form, setForm] = useState({});
    const { id } = useParams() as { id: string };
    const { data, loading } = useFetch<{ name: string; content: IForm }>(
        endpoints.selfEvaluation.questionnaireTemplate.getTemplate(id),
        '',
    );

    // Mentés&Visszatöltés kliens oldalon
    const lskey = 'qtemplate_' + user.id + '_' + id;
    useEffect(() => {
        try {
            setForm(JSON.parse(localStorage.getItem(lskey) ?? '{}'));
        } catch (e) {
            console.error('Storage get error', e);
        }
    }, [lskey]);

    useEffect(() => {
        try {
            localStorage.setItem(lskey, JSON.stringify(form));
        } catch (e) {
            console.error('Storage save error', e);
        }
    }, [form, lskey]);

    if (loading) return <MCCLoading />;

    return (
        <FormPreview
            formToSet={form}
            setForm={setForm}
            form={data.content}
            image={Logo}
            name={data.name}
            notificationSuccess={formFilledSuccessfullyMessage}
            onSubmit={dummyAsyncFunction}
            fileProps={fileProps}
        />
    );
}
