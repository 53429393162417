import Grid from '@mui/material/Grid';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { TableDeleteButton, TableEditButton } from '@silinfo/front-end-template';
import { clientEndPoints } from '../../../utils/clientEndPoints';
import apiKeysService from '../../../services/administration/apiKeysService';
import { useDispatch } from 'react-redux';
import { create } from '../../../store/notification';
import { useCallback } from 'react';

export default function BtnCellRenderer(params: GridRenderCellParams & { refreshing: () => void }) {
    const dispatch = useDispatch();

    const handleDelete = useCallback(() => {
        apiKeysService
            .delete(params.row.id)
            .then(() => {
                dispatch(create({ type: 'success', message: 'Sikeres törlés.' }));
                params.refreshing();
            })
            .catch(() => dispatch(create({ type: 'error', message: 'Sikertelen törlés.' })));
    }, [params, dispatch]);

    return (
        <Grid container spacing={1} justifyContent="center">
            <Grid item>
                <TableEditButton
                    tooltip={'Szerkesztés'}
                    target={clientEndPoints.admin_api_keys_edit.replace(':apiKeyId', params.row.id?.toString())}
                />
            </Grid>
            <Grid item>
                <TableDeleteButton
                    tooltip={'Törlés'}
                    deleteAction={handleDelete}
                    confirmationElement={params.row.name}
                    style={{ marginRight: '5px' }}
                />
            </Grid>
        </Grid>
    );
}
