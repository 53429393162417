import InfoIcon from '@mui/icons-material/Info';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { Dialog, TableButton } from '@silinfo/front-end-template';
import i18n from '../../../i18n';
import { EventLog, SystemLog, IData } from '../../../utils/Interfaces/interfaces';
import { useStyles } from '../SystemLog/SystemLog/SystemLogs';
import { camelToSnake } from '../../../utils/caseConverter';

export default function BtnCellRenderer(params: GridRenderCellParams) {
    const classes = useStyles();

    const data = params.row;
    const objectType = params.row['@type']?.toLowerCase();

    function updateCellRenderer(obj: SystemLog | EventLog, field: string, state: string) {
        if (state === 'old') {
            if (obj.changesArray.changes[field].old == true) {
                return i18n.exists('administration.systemlog.changes_table.column.yes')
                    ? i18n.t('administration.systemlog.changes_table.column.yes')
                    : String(obj.changesArray.changes[field].old);
            } else if (obj.changesArray.changes[field].old == false) {
                return i18n.exists('administration.systemlog.changes_table.column.no')
                    ? i18n.t('administration.systemlog.changes_table.column.no')
                    : String(obj.changesArray.changes[field].old);
            } else if (
                (obj.changesArray.changes[field].old as unknown as Record<string, unknown>)?.loggingIsActive == true
            ) {
                return i18n.exists('administration.systemlog.changes_table.column.yes')
                    ? i18n.t('administration.systemlog.changes_table.column.yes')
                    : String(obj.changesArray.changes[field].old);
            } else if (
                (obj.changesArray.changes[field].old as unknown as Record<string, unknown>)?.loggingIsActive == false
            ) {
                return i18n.exists('administration.systemlog.changes_table.column.no')
                    ? i18n.t('administration.systemlog.changes_table.column.no')
                    : String(obj.changesArray.changes[field].old);
            }
            return i18n.exists('administration.systemlog.changes_table.column.' + obj.changesArray.changes[field].old)
                ? i18n.t('administration.systemlog.changes_table.column.' + obj.changesArray.changes[field].old)
                : normalizeData(obj.changesArray.changes[field].old, field);
        } else if (state === 'new') {
            if (obj.changesArray.changes[field].new == true) {
                return i18n.exists('administration.systemlog.changes_table.column.yes')
                    ? i18n.t('administration.systemlog.changes_table.column.yes')
                    : String(obj.changesArray.changes[field].new);
            } else if (obj.changesArray.changes[field].new == false) {
                return i18n.exists('administration.systemlog.changes_table.column.no')
                    ? i18n.t('administration.systemlog.changes_table.column.no')
                    : String(obj.changesArray.changes[field].new);
            } else if (
                (obj.changesArray.changes[field].new as unknown as Record<string, unknown>)?.loggingIsActive == true
            ) {
                return i18n.exists('administration.systemlog.changes_table.column.yes')
                    ? i18n.t('administration.systemlog.changes_table.column.yes')
                    : String(obj.changesArray.changes[field].new);
            } else if (
                (obj.changesArray.changes[field].new as unknown as Record<string, unknown>)?.loggingIsActive == false
            ) {
                return i18n.exists('administration.systemlog.changes_table.column.no')
                    ? i18n.t('administration.systemlog.changes_table.column.no')
                    : String(obj.changesArray.changes[field].new);
            }

            return i18n.exists('administration.systemlog.changes_table.column.' + obj.changesArray.changes[field].new)
                ? i18n.t('administration.systemlog.changes_table.column.' + obj.changesArray.changes[field].new)
                : normalizeData(obj.changesArray.changes[field].new, field);
        }
    }

    function deleteOrCreateCellRenderer(obj: SystemLog, field: keyof SystemLog) {
        if (i18n.exists('administration.settings.' + obj?.changesArray?.current[field])) {
            return i18n.t('administration.settings.' + obj?.changesArray?.current[field]);
        } else if (obj?.changesArray?.current[field] == true) {
            return i18n.exists('administration.systemlog.changes_table.column.yes')
                ? i18n.t('administration.systemlog.changes_table.column.yes')
                : String(obj[field]);
        } else if (obj?.changesArray?.current[field] == false) {
            return i18n.exists('administration.systemlog.changes_table.column.no')
                ? i18n.t('administration.systemlog.changes_table.column.no')
                : String(obj[field]);
        } else if ((obj?.changesArray?.current[field] as Record<string, unknown>)?.loggingIsActive == true) {
            return i18n.exists('administration.systemlog.changes_table.column.yes')
                ? i18n.t('administration.systemlog.changes_table.column.yes')
                : String(obj[field]);
        } else if ((obj?.changesArray?.current[field] as Record<string, unknown>)?.loggingIsActive == false) {
            return i18n.exists('administration.systemlog.changes_table.column.no')
                ? i18n.t('administration.systemlog.changes_table.column.no')
                : String(obj[field]);
        }

        return i18n.exists('administration.systemlog.changes_table.column.' + obj?.changesArray?.current[field])
            ? i18n.t('administration.systemlog.changes_table.column.' + obj?.changesArray?.current[field])
            : normalizeData(obj.changesArray.current[field] as IData, field);
    }

    function arrayNormalizer(data: IData[]) {
        return data
            .filter((element) => !!element)
            .map((element) => {
                return (
                    element.campus ||
                    (element.trainingProgram ? element.trainingLevel + ' / ' + element.trainingProgram : '') ||
                    element
                );
            })
            .join(', ');
    }

    function normalizeData(data: IData[] | IData | boolean, field?: string) {
        if (typeof data === 'boolean') {
            return String(data);
        }

        if (data === null) {
            return '-';
        }

        if (field === 'roles' && Array.isArray(data)) {
            return data
                .map((element) => {
                    return i18n.t('roles.' + element);
                })
                .join(', ');
        }
        if (field === 'collegeYears' && Array.isArray(data)) {
            return data
                .map((element) => {
                    return element.name;
                })
                .join(' ');
        }
        if (Array.isArray(data)) {
            return arrayNormalizer(data);
        }
        if (String(data) === '[object Object]') {
            if (data.name) {
                return String(data.name);
            } else if (data.courseName) {
                return String(data.courseName);
            } else if (data.user) {
                return data.user.lastName + ' ' + data.user.firstName;
            } else {
                const objectArray = Object.values(data);
                if (Array.isArray(objectArray)) {
                    return arrayNormalizer(objectArray);
                }
                return 'FILE PATH';
            }
        }
        return String(data);
    }

    let dataToShow = null;
    if (data.action === 'update') {
        dataToShow = data?.changesArray?.changes;
    } else if (data.action === 'preRemove' || data.action === 'create' || data.action === 'charmLog') {
        dataToShow = data?.changesArray?.current;
    } else if (data.action === 'delete') {
        dataToShow = data?.changesArray?.changes;
    }

    return (
        <Dialog
            opener={
                <TableButton
                    tooltip={`Részletek`}
                    color={'info'}
                    style={{ marginRight: '5px' }}
                    className={classes.eyeIcon}
                >
                    <InfoIcon />
                </TableButton>
            }
            title="Változások"
            fullWidth
            maxWidth="md"
        >
            <div style={{ width: '100%', overflow: 'auto' }}>
                <Table style={{ minWidth: '400px' }}>
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.tableHeaderCell}>Kulcs</TableCell>
                            <TableCell className={classes.tableHeaderCell}>Régi érték</TableCell>
                            <TableCell className={classes.tableHeaderCell}>Új érték</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(data.action === 'preRemove' || data.action === 'create' || data.action === 'charmLog') &&
                            !!dataToShow &&
                            Object.keys(dataToShow)
                                .filter((elem: string) => elem !== 'profilePicture')
                                .map((field: string, index: number) => {
                                    if (field !== 'translations') {
                                        return (
                                            <TableRow key={index}>
                                                <TableCell>
                                                    <>
                                                        {
                                                            i18n.t(
                                                                'administration.systemlog.changes_table.row.' +
                                                                    camelToSnake(field),
                                                            ) as string
                                                        }
                                                    </>
                                                </TableCell>
                                                {data.action === 'preRemove' ? (
                                                    <>
                                                        <TableCell>
                                                            <div
                                                                dangerouslySetInnerHTML={{
                                                                    __html: deleteOrCreateCellRenderer(
                                                                        data,
                                                                        field as keyof SystemLog,
                                                                    ),
                                                                }}
                                                            />
                                                        </TableCell>
                                                        <TableCell>-</TableCell>
                                                    </>
                                                ) : (
                                                    <>
                                                        <TableCell>-</TableCell>
                                                        <TableCell>
                                                            <div
                                                                dangerouslySetInnerHTML={{
                                                                    __html: deleteOrCreateCellRenderer(
                                                                        data,
                                                                        field as keyof SystemLog,
                                                                    ),
                                                                }}
                                                            />
                                                        </TableCell>
                                                    </>
                                                )}
                                            </TableRow>
                                        );
                                    }
                                })}
                        {(data.action === 'update' || data.action === 'delete') &&
                            !!dataToShow &&
                            Object.keys(dataToShow)
                                .filter((elem: string) => elem !== 'profilePicture')
                                .map((field: string, index: number) => {
                                    if (field !== 'translations') {
                                        return (
                                            <TableRow key={index}>
                                                <TableCell>
                                                    <>
                                                        {
                                                            i18n.t(
                                                                'administration.systemlog.changes_table.row.' +
                                                                    camelToSnake(field),
                                                            ) as string
                                                        }
                                                    </>
                                                </TableCell>
                                                <TableCell>{updateCellRenderer(data, field, 'old')}</TableCell>
                                                <TableCell>{updateCellRenderer(data, field, 'new')}</TableCell>
                                            </TableRow>
                                        );
                                    }
                                })}
                        <TableRow style={{ background: '#f2f2f2' }}>
                            <TableCell colSpan={3}></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Rendszernapló ID</TableCell>
                            <TableCell>{data.id}</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Esemény</TableCell>
                            <TableCell colSpan={3}>
                                {objectType
                                    ? (i18n.t('administration.' + objectType + '.action.' + data.action) as string)
                                    : (i18n.t('administration.systemlog.action.' + data.action) as string)}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Típus</TableCell>
                            <TableCell colSpan={2}>
                                {data.type
                                    ? (i18n.t('administration.log.type.' + data.type) as string)
                                    : (i18n.t('administration.log.type.' + data.objectType) as string)}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Felhasználó</TableCell>
                            <TableCell colSpan={2}>{data.actor && `${data.actor?.fullName}`}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </div>
        </Dialog>
    );
}
