import Grid from '@mui/material/Grid';
import { Accordion, AutoloadTable, FieldGenerator, NewButton } from '@silinfo/front-end-template';
import { ChangeEvent, MutableRefObject, Ref, useCallback, useEffect, useRef, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import EndpointProvider from '../../../../components/EndpointProvider';
import courseTargetAudienceService from '../../../../services/courseManagement/courseTargetAudience';
import useAccessCheck from '../../../../utils/useAccessCheck';
import AddTargetAudienceButton from './AddTargetAudienceButton';
import { tableColumns } from './fields';
import { Form, Formik, FormikProps, FormikValues } from 'formik';
import Button from '@mui/material/Button';
import axios from 'axios';
import { create } from '../../../../store/notification';
import { useDispatch } from 'react-redux';
import { Option } from '../../../../utils/AppConst';
import MCCLoading from '../../../../components/MCCLoading';

export const campus = 0;

interface FormValues {
    tenant: string | null;
    campus: string | null;
    trainingLevel: string | null;
    trainingProgram: string | null;
    collegeYear: string | null;
}

export default function TargetAudience(props: { closed: boolean; readOnly: boolean }) {
    const { courseId } = useParams() as { courseId: string };
    const id: number = parseInt(courseId);
    const [searchParams] = useSearchParams();
    const courseTenant = searchParams.get('courseTenant');
    const [tableLoading, setTableLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const hasAccessToEdit = useAccessCheck(['course_manamagement_course_edit_base']);
    const { closed, readOnly } = props;
    const [filterValues, setFilterValues] = useState({});
    const [options, setOptions] = useState<{
        tenants: Option[];
        campuses: Option[];
        levels: Option[];
        programs: Option[];
        collegeYear: Option[];
    }>({
        tenants: [],
        campuses: [],
        levels: [],
        programs: [],
        collegeYear: [],
    });
    const [tenantSelected, setTenantSelected] = useState<string | null>(null);
    const [campusSelected, setCampusSelected] = useState<string | null>(null);
    const [levelSelected, setLevelSelected] = useState<string | null>(null);
    const [programSelected, setProgramSelected] = useState<string | null>(null);
    const [_collegeYearSelected, setCollegeYearSelected] = useState<string | null>(null);
    const formikPropsRef: MutableRefObject<FormikProps<unknown> | null> = useRef(null);

    const dispatch = useDispatch();
    const fetchData = useCallback(() => {
        setLoading(true);
        axios
            .all([courseTargetAudienceService.getTenants(courseId)])
            .then((res) => {
                setOptions((prev) => {
                    return {
                        ...prev,
                        tenants: res[0]?.data ?? [],
                    };
                });
            })
            .catch(() =>
                dispatch(
                    create({
                        type: 'error',
                        message: 'Hiba a betöltés során!',
                    }),
                ),
            )
            .finally(() => {
                setLoading(false);
            });
    }, [courseId, dispatch]);
    useEffect(() => {
        if (tenantSelected) {
            courseTargetAudienceService
                .getCampuses(tenantSelected, courseId)
                .then((res) => {
                    setOptions((prev) => ({
                        ...prev,
                        campuses: res.data ?? [],
                    }));
                    setCampusSelected(null);
                    setLevelSelected(null);
                    setProgramSelected(null);
                    if (formikPropsRef.current) {
                        formikPropsRef.current.setValues((prevValues: FormValues) => ({
                            ...prevValues,
                            campus: null,
                            trainingLevel: null,
                            trainingProgram: null,
                            collegeYear: null,
                        }));
                    }
                })
                .catch(() =>
                    dispatch(
                        create({
                            type: 'error',
                            message: 'Hiba a betöltés során!',
                        }),
                    ),
                );
        }
    }, [tenantSelected, dispatch, courseId]);

    useEffect(() => {
        if (campusSelected) {
            courseTargetAudienceService
                .getLevels(courseId, tenantSelected ?? '')
                .then((res) => {
                    setOptions((prev) => ({
                        ...prev,
                        levels: res.data ?? [],
                    }));
                    setLevelSelected(null);
                    setProgramSelected(null);
                    if (formikPropsRef.current) {
                        formikPropsRef.current.setValues((prevValues: FormValues) => ({
                            ...prevValues,
                            trainingLevel: null,
                            trainingProgram: null,
                            collegeYear: null,
                        }));
                    }
                })
                .catch((error) => {
                    dispatch(
                        create({
                            type: 'error',
                            message: 'Hiba a betöltés során!',
                        }),
                    );
                    console.error(error);
                });
        }
    }, [tenantSelected, dispatch, courseId, campusSelected]);

    useEffect(() => {
        if (levelSelected) {
            courseTargetAudienceService
                .getPrograms(courseId, tenantSelected ?? '')
                .then((res) => {
                    setOptions((prev) => ({
                        ...prev,
                        programs: res.data ?? [],
                    }));
                    setProgramSelected(null);
                    if (formikPropsRef.current) {
                        formikPropsRef.current.setValues((prevValues: FormValues) => ({
                            ...prevValues,
                            trainingProgram: null,
                            collegeYear: null,
                        }));
                    }
                })
                .catch((error) => {
                    dispatch(
                        create({
                            type: 'error',
                            message: 'Hiba a betöltés során!',
                        }),
                    );
                    console.error(error);
                });
        }
    }, [tenantSelected, dispatch, courseId, levelSelected]);

    useEffect(() => {
        if (programSelected) {
            courseTargetAudienceService
                .getYears(courseId, tenantSelected ?? '')
                .then((res) => {
                    setOptions((prev) => ({
                        ...prev,
                        collegeYear: res.data ?? [],
                    }));
                    if (formikPropsRef.current) {
                        formikPropsRef.current.setValues((prevValues: FormValues) => ({
                            ...prevValues,
                        }));
                    }
                })
                .catch((error) => {
                    dispatch(
                        create({
                            type: 'error',
                            message: 'Hiba a betöltés során!',
                        }),
                    );
                    console.error(error);
                });
        }
    }, [tenantSelected, dispatch, courseId, programSelected]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const submitFilters = (values: FormikValues) => {
        const tenantNames = values['tenant'];
        const campusNames = values['campus'];
        const levelNames = values['trainingLevel'];
        const programNames = values['trainingProgram'];
        const yearNames = values['collegeYear'];
        setFilterValues({
            tenant: tenantNames,
            campus: campusNames,
            trainingLevel: levelNames,
            trainingProgram: programNames,
            collegeYear: yearNames,
        });
        setTableLoading(true);
        setTableLoading(false);
    };

    return (
        <Grid container spacing={2}>
            <Grid item container xs={12} justifyContent="flex-end">
                {!closed && !readOnly && (
                    <EndpointProvider endpoints={['course_manamagement_course_edit_base']}>
                        <Grid item>
                            <AddTargetAudienceButton
                                title="Célközönség hozzáadása"
                                submitBtnTitle="Hozzáadás"
                                campus={campus}
                                setTableLoading={setTableLoading}
                                opener={<NewButton>Célközönség hozzáadása</NewButton>}
                                onUpdate={fetchData}
                            />
                        </Grid>
                    </EndpointProvider>
                )}
            </Grid>
            <Grid item xs={12}>
                {loading ? (
                    <MCCLoading />
                ) : (
                    <Accordion title="Szűrő">
                        <Formik
                            initialValues={filterValues}
                            onSubmit={(formikValues) => submitFilters(formikValues)}
                            innerRef={formikPropsRef as Ref<FormikProps<FormikValues>>}
                        >
                            {(formikProps) => (
                                <Form>
                                    <Grid item container spacing={2}>
                                        <Grid item container xs={12} spacing={1}>
                                            {FieldGenerator({
                                                name: 'tenant',
                                                label: 'Tenant',
                                                type: 'select',
                                                options: options.tenants,
                                                format: { xs: 6 },
                                                props: {
                                                    disabled: options.tenants.length === 0,
                                                    onChange: (event: ChangeEvent<HTMLInputElement>) => {
                                                        formikProps.handleChange('tenant')(event);
                                                        setTenantSelected(event?.target?.value ?? '');
                                                    },
                                                },
                                                ...formikProps,
                                            })}
                                            {FieldGenerator({
                                                name: 'campus',
                                                label: 'Campus',
                                                type: 'select',
                                                options: options.campuses,
                                                format: { xs: 6 },
                                                fieldType: 'base',
                                                props: {
                                                    disabled: !tenantSelected,
                                                    onChange: (event: ChangeEvent<HTMLInputElement>) => {
                                                        formikProps.handleChange('campus')(event);
                                                        setCampusSelected(event?.target?.value ?? '');
                                                    },
                                                },
                                                ...formikProps,
                                            })}
                                            {FieldGenerator({
                                                name: 'trainingLevel',
                                                label: 'Képzési szint',
                                                type: 'select',
                                                options: options.levels,
                                                format: { xs: 6 },
                                                fieldType: 'base',
                                                props: {
                                                    disabled: !campusSelected,
                                                    onChange: (event: ChangeEvent<HTMLInputElement>) => {
                                                        formikProps.handleChange('trainingLevel')(event);
                                                        setLevelSelected(event?.target?.value ?? '');
                                                    },
                                                },
                                                ...formikProps,
                                            })}
                                            {FieldGenerator({
                                                name: 'trainingProgram',
                                                label: 'Képzési program',
                                                type: 'select',
                                                options: options.programs,
                                                format: { xs: 6 },
                                                fieldType: 'base',
                                                props: {
                                                    disabled: !levelSelected,
                                                    onChange: (event: ChangeEvent<HTMLInputElement>) => {
                                                        formikProps.handleChange('trainingProgram')(event);
                                                        setProgramSelected(event?.target?.value ?? '');
                                                    },
                                                },
                                                ...formikProps,
                                            })}
                                            {FieldGenerator({
                                                name: 'collegeYear',
                                                label: 'Évfolyam',
                                                type: 'select',
                                                options: options.collegeYear,
                                                format: { xs: 6 },
                                                fieldType: 'base',
                                                props: {
                                                    disabled: !programSelected,
                                                    onChange: (event: ChangeEvent<HTMLInputElement>) => {
                                                        formikProps.handleChange('collegeYear')(event);
                                                        setCollegeYearSelected(event?.target?.value ?? '');
                                                    },
                                                },
                                                ...formikProps,
                                            })}
                                        </Grid>
                                        <Grid item container xs={12} spacing={2}>
                                            <Grid item>
                                                <Button variant="contained" color={'info'} type="submit" fullWidth>
                                                    Szűrés
                                                </Button>
                                            </Grid>
                                            <Grid item>
                                                <Button
                                                    color={'info'}
                                                    variant="outlined"
                                                    fullWidth
                                                    onClick={() => {
                                                        // eslint-disable-next-line react/prop-types
                                                        formikProps.resetForm();
                                                        setTenantSelected(null);
                                                        setCampusSelected(null);
                                                        setLevelSelected(null);
                                                        setProgramSelected(null);
                                                        setCollegeYearSelected(null);
                                                        formikProps.submitForm();
                                                    }}
                                                >
                                                    Alapértelmezett
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Form>
                            )}
                        </Formik>
                    </Accordion>
                )}
            </Grid>
            <Grid item xs={12}>
                <AutoloadTable
                    filter={(form) => {
                        return courseTargetAudienceService.filter(id, courseTenant, { ...form, ...filterValues });
                    }}
                    columns={
                        hasAccessToEdit && !readOnly
                            ? tableColumns(setTableLoading, fetchData)
                            : tableColumns(setTableLoading, fetchData).slice(0, -1)
                    }
                    title="Célközönség"
                    refresh={tableLoading}
                    responseKey="data"
                />
            </Grid>
        </Grid>
    );
}
