import { FormPreview } from '@silinfo/form-builder-2';
import { useFetch } from '@silinfo/front-end-template';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import MCCLoading from '../../../../components/MCCLoading';
import Logo from '../../../../images/MCC_logo_for_form.png';
import { RootState } from '../../../../store';
import { fileProps, formFilledSuccessfullyMessage } from '../../../../utils/AppConst';
import { endpoints } from '../../../../utils/endPoints';
import { IForm2 } from '../../../../components/PreviewTemplate/types';
import MultiFormPreview from '../../../../components/PreviewTemplate/MultiFormPreview';

const dummyAsyncFunction = async () => {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve('OK');
        }, 1000);
    });
};

export default function TemplateGroupPreview() {
    const { user } = useSelector((state: RootState) => state.auth);
    const [form, setForm] = useState({});
    const { id } = useParams() as { id: string };
    const { data, loading } = useFetch<{ name: string; forms: IForm2[] }>(
        endpoints.selfEvaluation.questionnaireGroup.getTemplate(id),
        '',
    );
    const [formToSet, setFormArr] = useState<Record<string, unknown>[]>([]);

    // Mentés&Visszatöltés kliens oldalon
    const lskey = 'qtemplategroup_' + user.id + '_' + id;
    useEffect(() => {
        try {
            setForm(JSON.parse(localStorage.getItem(lskey) ?? '{}'));
        } catch (e) {
            console.error('Storage get error', e);
        }
    }, [lskey]);

    useEffect(() => {
        try {
            localStorage.setItem(lskey, JSON.stringify(form));
        } catch (e) {
            console.error('Storage save error', e);
        }
    }, [form, lskey]);

    if (loading || !data?.forms) return <MCCLoading />;

    return (
        <>
            {data.forms.length === 1 && (
                <FormPreview
                    formToSet={form}
                    setForm={setForm}
                    form={data.forms[0]}
                    image={Logo}
                    name={data.forms[0].name || ''}
                    notificationSuccess={formFilledSuccessfullyMessage}
                    onSubmit={dummyAsyncFunction}
                    fileProps={fileProps}
                />
            )}
            {data.forms.length > 1 && (
                <MultiFormPreview
                    image={Logo}
                    dateFilled={false}
                    forms={data.forms}
                    onSubmit={dummyAsyncFunction}
                    formToSet={formToSet}
                    setForm={setFormArr}
                />
            )}
        </>
    );
}
