import { GridColDef, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid-pro';
import { FormikListPage, Link, TableButton, TableDeleteButton, TableEditButton } from '@silinfo/front-end-template';
import { useState, SetStateAction, Dispatch } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MCCLoading from '../../../../components/MCCLoading';
import universitiesService from '../../../../services/masterData/universities';
import { create } from '../../../../store/notification';
import { clientEndPoints } from '../../../../utils/clientEndPoints';
import useUniversityFields from './useUniversityFields';
import { RootState } from '../../../../store';
import Visibility from '@mui/icons-material/Visibility';
import { useTenant } from '../../../../components/TenantContext';

function BtnCellRenderer(
    params: GridRenderCellParams & { setRefresh: Dispatch<SetStateAction<boolean>>; readonly?: boolean },
) {
    const dispatch = useDispatch();
    if (params.readonly) {
        return (
            <Link to={clientEndPoints.md_general_universities_show.replace(':id', params.row.id.toString())}>
                <TableButton tooltip={'Adatok megtekintése'}>
                    <Visibility />
                </TableButton>
            </Link>
        );
    } else {
        return (
            <>
                <TableEditButton
                    tooltip={'Szerkesztés'}
                    sx={{ marginRight: '5px' }}
                    target={clientEndPoints.md_general_universities_edit.replace(':id', params.row.id.toString())}
                />
                <TableDeleteButton
                    tooltip={'Törlés'}
                    deleteAction={() => {
                        universitiesService
                            .delete(params.row.id)
                            .then(() => {
                                dispatch(create({ type: 'success', message: 'Sikeres törlés.' }));
                                params.setRefresh((prev) => !prev);
                            })
                            .catch(() => dispatch(create({ type: 'error', message: 'Sikertelen törlés.' })));
                    }}
                    confirmationElement={params.row.name}
                    sx={{ marginRight: '5px' }}
                />
            </>
        );
    }
}

export default function Universities() {
    const { user } = useSelector((state: RootState) => state.auth);
    const [refresh, setRefresh] = useState<boolean>(false);
    const { fields, loading } = useUniversityFields(refresh);
    const tenant = useTenant();

    const header = {
        breadcrumbs: {
            'masterData': 'Törzsadatok',
            [clientEndPoints.md_general]: 'Általános',
            'universities': 'Egyetemek',
        },
        title: 'Egyetemek',
        project: tenant || 'TAR',
    };

    if (loading) return <MCCLoading />;

    return (
        <FormikListPage
            service={universitiesService}
            header={header}
            refresh={refresh}
            autoload
            filter={{
                fields,
            }}
            defaultSort={{ name: 'asc' }}
            columns={
                [
                    { headerName: 'Név', field: 'name' },
                    { headerName: 'Rövid név', field: 'shortName', align: 'center' },
                    {
                        headerName: 'Ország',
                        field: 'country',
                        valueGetter: (params: GridValueGetterParams) => params.row.country?.countryName || '-',
                    },
                    {
                        headerName: 'Műveletek',
                        field: 'operations',
                        renderCell: (params: GridRenderCellParams) => (
                            <BtnCellRenderer setRefresh={setRefresh} readonly={!!user.archive} {...params} />
                        ),
                        sortable: false,
                    },
                ].map((column) => ({ flex: 1, minWidth: 150, headerAlign: 'center', ...column })) as GridColDef[]
            }
            newLabel={!user.archive ? 'Egyetem hozzáadása' : ''}
        />
    );
}
