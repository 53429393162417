import { GridRenderCellParams } from '@mui/x-data-grid';
import { TableButton } from '@silinfo/front-end-template';
import { clientEndPoints } from '../../../../utils/clientEndPoints';
import VisibilityIcon from '@mui/icons-material/Visibility';

export default function OperationsCellRenderer(params: GridRenderCellParams) {
    return (
        <>
            <TableButton
                color={'info'}
                onClick={() =>
                    window.open(
                        clientEndPoints.md_general_students_edit_detailed.replace(':id', params.row.studentId),
                        '_blank',
                    )
                }
                tooltip={`Hallgatói részletes oldal megnyitása`}
            >
                <VisibilityIcon />
            </TableButton>
        </>
    );
}
