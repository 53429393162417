import { endpoints } from '../../../utils/endPoints';
import { IForm } from '../../../utils/Interfaces/interfaces';
import { crudTemplate } from '../../templates';

export type TScoringType = 'point' | 'copper';

export interface ICourseType extends IForm {
    data: {
        id: number;
        name: string;
        active: string | boolean;
        courseCategory: string;
        trainingProgramRequirementCourseTypesCount: number;
        coursesCount: number;
        eventAssignable: string | boolean;
        scoringType?: TScoringType;
    };
}

const courseTypesService = {
    ...crudTemplate<ICourseType>(endpoints.masterData.courses.courseTypes.main),
};

export default courseTypesService;
