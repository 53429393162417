import { Link, Page, Table, TableButton, TableEditButton, Tooltip } from '@silinfo/front-end-template';
import { useEffect, useState } from 'react';
import { IPage } from './types';
import { fetchPages, header, initialPage } from './utils';
import { clientEndPoints } from '../../../utils/clientEndPoints';
import Visibility from '@mui/icons-material/Visibility';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid-pro';
import i18n from '../../../i18n';
import UpdateIcon from '@mui/icons-material/Update';
import Grid from '@mui/material/Grid';
import DeletePageConfirmModal from './DeletePageConfirmModal';
import IconButton from '@mui/material/IconButton';
import { theme } from '../../../utils/theme';
import DeleteIcon from '@mui/icons-material/Delete';
import { endpoints } from '../../../utils/endPoints';
import instance from '../../../api';
import { create } from '../../../store/notification';
import { useTenant } from '../../../components/TenantContext';

export default function Pages() {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState<boolean>(true);
    const [data, setData] = useState<IPage>(initialPage);
    const { user } = useSelector((state: RootState) => state.auth);
    const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
    const [toDelete, setToDelete] = useState({
        id: '',
        title: '',
    });
    const handleOpenDeleteModal = () => setDeleteModalOpen(true);
    const [refresh, setRefresh] = useState<boolean>(false);
    const tenant = useTenant();
    const headerWithTenant = header(tenant || 'TAR');

    const handleDelete = (id: string) => {
        instance
            .delete(endpoints.administration.pages.delete(id))
            .then(() => {
                dispatch(create({ type: 'success', message: 'Sikeres törlés' }));
                setDeleteModalOpen(false);
                setRefresh((prev) => !prev);
            })
            .catch(() => {
                dispatch(create({ type: 'error', message: 'Sikertelen törlés' }));
            });
    };

    useEffect(() => {
        fetchPages(setLoading, setData);
    }, [refresh]);

    const columns = [
        {
            headerName: 'Cím',
            field: 'title',
            valueGetter: (params: GridValueGetterParams) => params?.row?.translations?.['hu' || '']?.title || '', // TODO: Bejelentkezett user nyelve
        },
        {
            headerName: 'Típus',
            field: 'pageType',
            valueGetter: (params: GridValueGetterParams) => {
                return params?.row?.pageType ? i18n.t('master_data.pages.type.' + params?.row?.pageType) : '-';
            },
        },
        {
            headerName: 'Műveletek',
            field: 'operations',
            sortable: false,
            renderCell: (params: GridRenderCellParams) =>
                !user.archive ? (
                    <Grid container spacing={1}>
                        <Grid item>
                            {!params.row.pageType ? (
                                <Link
                                    to={clientEndPoints.admin_pages_edit.replace(':pageId', params.row.id.toString())}
                                >
                                    <TableButton tooltip={'Megtekintés'}>
                                        <Visibility />
                                    </TableButton>
                                </Link>
                            ) : (
                                <TableEditButton
                                    tooltip={'Szerkesztés'}
                                    target={clientEndPoints.admin_pages_edit.replace(
                                        ':pageId',
                                        params.row.id.toString(),
                                    )}
                                />
                            )}
                        </Grid>
                        {params.row.pageType && !params.row.assigned && (
                            <Grid item>
                                <Tooltip title="Oldal törlése">
                                    <Grid item>
                                        <IconButton
                                            size="small"
                                            sx={{
                                                borderWidth: '1px',
                                                borderStyle: 'solid',
                                                borderColor: theme.palette.error.main,
                                            }}
                                            onClick={() => {
                                                setToDelete({
                                                    id: params.row.id,
                                                    title: params?.row?.translations?.['hu' || '']?.title || '',
                                                });
                                                handleOpenDeleteModal();
                                            }}
                                        >
                                            <DeleteIcon color="error" />
                                        </IconButton>
                                    </Grid>
                                </Tooltip>
                            </Grid>
                        )}

                        <Grid item>
                            <Link
                                to={clientEndPoints.admin_pages_versions_list.replace(
                                    ':pageId',
                                    params.row.id.toString(),
                                )}
                            >
                                <TableButton tooltip={'Verziók kezelése'}>
                                    <UpdateIcon />
                                </TableButton>
                            </Link>
                        </Grid>
                    </Grid>
                ) : (
                    <Link to={clientEndPoints.admin_pages_versions_list.replace(':pageId', params.row.id.toString())}>
                        <TableButton tooltip={'Adatok megtekintése'}>
                            <Visibility />
                        </TableButton>
                    </Link>
                ),
        },
    ];

    const table = {
        columns: columns,
        rows: data,
        loading: loading,
        ...(user.archive
            ? {}
            : {
                  newButton: {
                      target: clientEndPoints.admin_pages_new,
                      text: 'Oldal hozzáadása',
                  },
              }),
    };

    return (
        <Page header={headerWithTenant}>
            <Table {...table} />

            <DeletePageConfirmModal
                openState={[deleteModalOpen, setDeleteModalOpen]}
                pageName={toDelete?.title ?? ''}
                handleDelete={() => handleDelete(toDelete?.id ?? '')}
            />
        </Page>
    );
}
