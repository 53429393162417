import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import { ICourseView } from '../../../pages/CourseManagement/Courses/types';
import { endpoints } from '../../../utils/endPoints';
import { Paper } from '../Paper';
import Label from './Label';
import { createStringFromUser, getBase64 } from '../../../utils/AppConst';
import { useEffect, useState } from 'react';
import ProfPic from '../../../images/image.svg';

const InstructorData = (props: ICourseView) => {
    const [profilePic, setProfilePic] = useState<string>(ProfPic);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (props.instructors?.length > 0) {
            setLoading(true);
            getBase64(
                `${endpoints.fileManagement.download}?id=${props.instructors[0].id}` +
                    (props.isGuest ? `&type=profile-image-guest` : `&type=profile-image`),
            )
                .then(setProfilePic)
                .finally(() => setLoading(false));
        }
    }, [props.instructors, props.isGuest]);

    return (
        <Grid item xs={12} md={4}>
            <Paper>
                <Grid item xs={12} container spacing={2}>
                    <Grid item xs={false} md={4}>
                        {loading ? (
                            <Skeleton
                                style={{
                                    paddingTop: '100%',
                                    width: '75%',
                                }}
                            />
                        ) : (
                            <div
                                style={{
                                    paddingTop: '100%',
                                    width: '75%',
                                    backgroundImage: `url(${profilePic})`,
                                    backgroundSize: 'contain',
                                    backgroundRepeat: 'no-repeat',
                                }}
                            />
                        )}
                    </Grid>
                    <Grid item container xs={12} md={8} spacing={4}>
                        <Grid item container xs={12}>
                            <Label>Felelős oktató(k)</Label>
                            <Grid item xs={12}>
                                {props.instructors?.length > 0 ? props.instructors?.map(createStringFromUser) : '-'}
                            </Grid>
                        </Grid>
                        <Grid item container xs={12}>
                            {props.guestInstructors && (
                                <>
                                    <Label>Vendégoktató(k)</Label>
                                    <Grid item xs={12}>
                                        {props.guestInstructors}
                                    </Grid>
                                </>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    );
};

export default InstructorData;
