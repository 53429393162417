import Grid from '@mui/material/Grid';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { TableDeleteButton, TableEditButton } from '@silinfo/front-end-template';
import { Dispatch, SetStateAction, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import documentService from '../../../services/administration/document';
import { create } from '../../../store/notification';
import { clientEndPoints } from '../../../utils/clientEndPoints';
import { endpoints } from '../../../utils/endPoints';
import OnClickDownloadButton from './OnClickDownloadButton';
import { RootState } from '../../../store';

export default function OperationsCellRenderer(
    params: GridRenderCellParams & { setRefresh: Dispatch<SetStateAction<boolean>> },
) {
    const dispatch = useDispatch();
    const { user } = useSelector((state: RootState) => state.auth);

    const handleDelete = useCallback(() => {
        documentService
            .delete(params.row.id)
            .then(() => {
                dispatch(create({ type: 'success', message: 'Sikeres törlés.' }));
                params.setRefresh((prev) => !prev);
            })
            .catch(() => dispatch(create({ type: 'error', message: 'Sikertelen törlés.' })));
    }, [params, dispatch]);

    return (
        <>
            {!user.archive && (
                <>
                    <TableEditButton
                        tooltip={'Szerkesztés'}
                        sx={{ marginRight: '5px' }}
                        target={clientEndPoints.admin_documents_edit.replace(':id', params.row.id.toString())}
                    />
                    <TableDeleteButton
                        tooltip={'Törlés'}
                        deleteAction={handleDelete}
                        confirmationElement={params.row.name}
                        sx={{ marginRight: '5px' }}
                    />
                </>
            )}
            <Grid item>
                <OnClickDownloadButton
                    filename={params.row.fileName || 'dokumentum.pdf'}
                    url={`${endpoints.fileManagement.download}?id=${params.row.id.toString()}&type=document-temp`}
                />
            </Grid>
        </>
    );
}
