import TemplatePreview from '../pages/Administration/QuestionnaireTemplate/Preview';
import QuestionnairePreview from '../pages/Admission/ApplicantReport/QuestionnairePreview';
import ReportPreview from '../pages/SelfEvaluation/Report/Preview';
import Filler from '../studentPages/Questionnaires/Filler';
import SelfAssessmentQuestionnaire from '../studentPages/SelfAssessment/Questionnaire';
import Summary from '../studentPages/SelfAssessment/Summary';
import { endpoints } from '../utils/endPoints';
import TemplateGroupPreview from '../pages/Administration/QuestionnaireTemplate/PreviewGroup';

const studentSelfEvaluation = {
    path: 'student/self-assessment/:id',
    element: <SelfAssessmentQuestionnaire />,
};

const templatePreview = {
    path: 'self-evaluation/templates/:id/preview',
    element: <TemplatePreview />,
    name: 'templates_preview',
};

const templateGroupPreview = {
    path: 'self-evaluation/template-groups/:id/preview',
    element: <TemplateGroupPreview />,
    name: 'template_groups_preview',
};

const selfEvaluationReport = {
    path: 'self-evaluation/report/:id/preview',
    element: <ReportPreview />,
};

const studentSummary = {
    path: 'student/self-assessment/summary/:id',
    element: (
        <Summary type="summary" keyPrefix="student_summary" makeUrlFromId={endpoints.students.selfEvaluation.summary} />
    ),
};

const adminSummary = {
    path: 'self-evaluation/report/:id/summary',
    element: (
        <Summary type="summary" keyPrefix="student_summary" makeUrlFromId={endpoints.selfEvaluation.report.summary} />
    ),
};

const studentPreviousSummary = {
    path: 'student/self-assessment/previous-summary/:id',
    element: (
        <Summary
            type="previousSummary"
            keyPrefix="student_previous_summary"
            makeUrlFromId={endpoints.students.selfEvaluation.previousSummary}
        />
    ),
};

const adminPreviousSummary = {
    path: 'self-evaluation/report/:id/previous-summary',
    element: (
        <Summary
            type="previousSummary"
            keyPrefix="student_previous_summary"
            makeUrlFromId={endpoints.selfEvaluation.report.previousSummary}
        />
    ),
};

const fillForm = {
    path: 'student/questionnaires/fill/:id',
    element: <Filler />,
};

const admissionFormPreview = {
    path: 'admission/applicant-report/templates/:id/preview',
    element: <QuestionnairePreview />,
};

const admissionQuestionnairePreview = {
    path: '/admission/questionnaire/:id/preview',
    element: <TemplatePreview />,
};

const admissionRiportFormPreview = {
    path: 'admission/riport/templates/:id/preview',
    element: <QuestionnairePreview />,
};

const formPreviews = {
    path: '/',
    children: [
        studentSelfEvaluation,
        templatePreview,
        templateGroupPreview,
        selfEvaluationReport,
        studentSummary,
        adminSummary,
        studentPreviousSummary,
        adminPreviousSummary,
        fillForm,
        admissionFormPreview,
        admissionQuestionnairePreview,
        admissionRiportFormPreview,
    ],
};

export default formPreviews;
