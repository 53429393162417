import axios, { AxiosError } from 'axios';
import { history, store } from '../store';
import { decrement, increment } from '../store/loading';
import { create } from '../store/notification';
import { axiosErrorRedirect, setUnseenMessagesInterval, UNSEEN_MESSAGES_CHECK_INTERVAL } from '../utils/AppConst';
import { getJWTToken, getLastBackendRequestTime, setLastBackendRequestTime } from '../utils/AuthHelper';
import { endpoints } from '../utils/endPoints';

const instance = axios.create({
    headers: {
        //     'Access-Control-Allow-Origin': '*', sajnos cors errort okoz
        'Content-Type': 'application/json',
        //    'X-Requested-With': 'XMLHttpRequest', sajnos cors errort okoz
    },
    responseType: 'json',
    //withCredentials: true,
});

instance.interceptors.request.use(
    (config) => {
        store.dispatch(increment(config.url || ''));
        if (config.url && !config.url.includes(endpoints.seen)) {
            const lastBackendRequestTime = getLastBackendRequestTime();
            if (Date.now() - lastBackendRequestTime > UNSEEN_MESSAGES_CHECK_INTERVAL || !+lastBackendRequestTime) {
                setLastBackendRequestTime();
                if (!window.location.pathname.includes('/felveteli')) {
                    setUnseenMessagesInterval(0);
                }
            }
            setLastBackendRequestTime();
        }
        if (!config.headers?.Authorization) {
            const AUTH_TOKEN = getJWTToken();
            if (AUTH_TOKEN)
                config.headers = Object.assign({}, config.headers, {
                    Authorization: `Bearer ${AUTH_TOKEN}`,
                });

            const XSwitchUser = localStorage.getItem('X-Switch-User');
            if (XSwitchUser)
                config.headers = Object.assign({}, config.headers, {
                    'X-Switch-User': XSwitchUser,
                });
        }

        return config;
    },
    (error) => Promise.reject(error),
);

const handleErrorRedirect = (status?: number): string => {
    if (!status) return '';
    switch (status) {
        case 403:
            return '/403';
        case 404:
            return '/404';
        default:
            return '';
    }
};

const handleErrorMessage = (status?: number): string => {
    if (!status) return '';
    switch (status) {
        case 413:
            return 'A feltöltött fájl mérete túl nagy.';
        case 500:
            return 'Az érintett funkció karbantartás alatt áll. Kérjük, próbáld újra később!';
        case 502:
        case 503:
            return 'A szerver karbantartás alatt áll. Kérjük, néhány perc múlva próbáld újra!';
        default:
            return '';
    }
};

// Add a response interceptor
instance.interceptors.response.use(
    function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        store.dispatch(decrement(response.config.url || ''));
        return response;
    },
    // remember to make this async as the store action will
    // need to be awaited
    async function (error: AxiosError) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        store.dispatch(decrement(error?.config?.url || ''));

        if (error?.response?.status === 401) {
            axiosErrorRedirect(error);

            return Promise.reject(error);
        } else {
            const redirect = handleErrorRedirect(error.response?.status);
            if (redirect && window.location.pathname !== '/felveteli') {
                history.push(redirect, { error });
            }
            const message = error.response?.data.message || handleErrorMessage(error.response?.status);
            if (message) {
                store.dispatch(create({ message, type: 'error' }));
            }
        }

        return Promise.reject(error);
    },
);

instance.interceptors.request.use((config) => {
    if (config.method && config.method?.toLocaleUpperCase() === 'PATCH') {
        config.headers = {
            ...config.headers,
            'Content-Type': 'application/merge-patch+json',
        };
    }
    return config;
});

export default instance;
