import Grid from '@mui/material/Grid';
import { FormBuilder } from '@silinfo/form-builder-2';
import { EditorProps } from '@silinfo/form-builder-2/lib/esm/components/FormBuilder/types';
import { Accordion, CKEditor, Link, Page } from '@silinfo/front-end-template';
import { memo, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Form from '../../../../components/Form/Form';
import MCCLoading from '../../../../components/MCCLoading';
import questionnaireTemplateService from '../../../../services/selfEvalution/questionnaireTemplate';
import { clientEndPoints } from '../../../../utils/clientEndPoints';
import { ITemplatesForm } from './types';
import { fields, header, initialValue } from './utils';
import Button from '@mui/material/Button';
import styles from './Form.module.scss';
import PreviewButton from './PreviewButton';
import CourseEvaulationFields from './CourseEvaluationFields';
import { useTenant } from '../../../../components/TenantContext';

const OptimizedFormBuilder = memo(FormBuilder);
const EditorComponent = (props: EditorProps) => (
    <Grid item xs={12}>
        <CKEditor {...props} />
    </Grid>
);

export default function TemplatesForm() {
    const { id } = useParams();
    const title = id ? 'Kérdőívsablon szerkesztése' : 'Új kérdőívsablon';
    const [initialValues, setInitialValues] = useState(initialValue);
    const [formBuilder, setFormBuilder] = useState(initialValue.content);
    const [loading, setLoading] = useState(false);
    const tenant = useTenant();
    const handleSubmit = useCallback(
        (values: ITemplatesForm) => {
            const newValues = { ...values, content: formBuilder };
            return id
                ? questionnaireTemplateService.update(id, newValues)
                : questionnaireTemplateService.create(newValues);
        },
        [formBuilder, id],
    );

    useEffect(() => {
        if (id) {
            setLoading(true);
            questionnaireTemplateService
                .get(id)
                .then((res) => {
                    setInitialValues(res.data.data);
                    setFormBuilder(JSON.parse(res.data.data.content));
                })
                .finally(() => setLoading(false));
        }
    }, [id]);

    if (loading) return <MCCLoading />;

    return (
        <Page header={header(title, tenant || 'TAR')}>
            <Grid item xs={12} className={styles.resetStyles}>
                <Accordion title={title} defaultExpanded disableClosing>
                    <Form
                        onSubmit={handleSubmit}
                        fields={fields}
                        initialValues={initialValues}
                        back={clientEndPoints.admin_questionnaire_templates + '?tab=0'}
                        hideButtons
                    >
                        {({ values }) => (
                            <Grid item xs={12} container spacing={2}>
                                {values.type === 'course_evaluation_questionnaire' ? <CourseEvaulationFields /> : null}
                                <Grid item xs={12}>
                                    <OptimizedFormBuilder
                                        form={formBuilder}
                                        setForm={setFormBuilder}
                                        editor={EditorComponent}
                                        options={{
                                            multiPanel: true,
                                            file: true,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} container spacing={1}>
                                    <Grid item>
                                        <Button variant="contained" color="primary" type="submit">
                                            Mentés
                                        </Button>
                                    </Grid>
                                    <PreviewButton formBuilder={formBuilder} />
                                    <Grid item>
                                        <Link to={clientEndPoints.admin_questionnaire_templates + '?tab=0'}>
                                            <Button variant="outlined" color="primary">
                                                Vissza
                                            </Button>
                                        </Link>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                    </Form>
                </Accordion>
            </Grid>
        </Page>
    );
}
