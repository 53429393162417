import instance from '../../api';
import IEntityBase from '../../interface/IEntityBase';
import { IAddAdministrationFeeFormToSubmit } from '../../pages/Finance/AdministrationFees/Form';
import { fixDateRangeFields } from '../../utils/AppConst';
import { endpoints } from '../../utils/endPoints';
import { IForm } from '../../utils/Interfaces/interfaces';
import { crudTemplate } from '../templates';

export type ActualStatusType = 'internal' | 'external';
export type SemesterStatusType = 'active' | 'passive' | 'foreign_scholarship';

export interface IStudentResponse extends Record<string, string | boolean | IEntityBase> {
    campus: IEntityBase;
    trainingLevel: IEntityBase;
    trainingProgram: IEntityBase;
    collegeYear: IEntityBase;
}

export interface IStudentsForm
    extends Omit<IStudentResponse, 'campus' | 'trainingLevel' | 'trainingProgram' | 'collegeYear'>,
        IForm {
    userId: string;
    lastName: string;
    firstName: string;
    email: string;
    campus: string;
    trainingLevel: string;
    trainingProgram: string;
    collegeYear: string;
    semesterStatus: SemesterStatusType;
    actualStatus: ActualStatusType;
    functionalUser: string;
    building: string;
    floor: string;
    room: string;
    mccId: string;
    activated: boolean;
}

export interface IAddAdministrationFeeForm {
    administrationFeeVal: string;
    students: number[];
}

const studentsService = {
    ...crudTemplate<IStudentsForm>(endpoints.masterData.students.main),
    updatePost: (id: number | string, form: IStudentsForm) =>
        instance.post(endpoints.masterData.students.main + '/' + id, form),
    updateDetailed: <T>(id: number | string, form: T) =>
        instance.post(endpoints.masterData.students.detailed.replace('{studentId}', id.toString()), form),
    downloadUniversityAdmissionDocument: (id: number) =>
        instance.get(`${endpoints.fileManagement.download}?id=${id}&type=student-document&subType=admission_decision`),
    getDetailed: (id: number | string) =>
        instance.get(endpoints.masterData.students.detailed.replace('{studentId}', id.toString())),
    administrationFees: <T extends Record<string, unknown>>(form: T) =>
        instance.get(endpoints.masterData.students.administrationFees, { params: fixDateRangeFields(form) }),
    listAdministrationFeeTypes: () => instance.get(endpoints.masterData.students.listAdministrationFeeTypes),
    getAdministrationFee: () => instance.get(endpoints.masterData.students.administrationFee),
    deleteAdministrationFeeFromStudent: (feeId: number | string, studentId: number | string) =>
        instance.delete(
            endpoints.masterData.students.deleteAdministrationFee
                .replace('{feeId}', feeId.toString())
                .replace('{studentId}', studentId.toString()),
        ),
    findByEmail: (email: string) => instance.get(endpoints.masterData.students.findByEmail + '/' + email),
    addAdministrationFee: (form: IAddAdministrationFeeFormToSubmit) =>
        instance.post(endpoints.masterData.students.addAdministrationFee, form),
    /*--Részletes nézet opciók */
    universities: () => instance.get(endpoints.masterData.students.universities),
    actualStatuses: () => instance.get(endpoints.masterData.students.actualStatuses),
    semesterStatuses: () => instance.get(endpoints.masterData.students.semesterStatuses),
    nationalities: () => instance.get(endpoints.masterData.students.nationalities),
    listFieldRules: () => instance.get(endpoints.masterData.students.listFieldRules),
    trainingForms: () => instance.get(endpoints.masterData.students.trainingForms),
    financeForms: () => instance.get(endpoints.masterData.students.financeForms),
    legalRelationships: () => instance.get(endpoints.masterData.students.legalRelationships),
    campuses: () => instance.get(endpoints.masterData.students.campuses),
    trainingLevels: () => instance.get(endpoints.masterData.students.trainingLevels),
    trainingPrograms: () => instance.get(endpoints.masterData.students.trainingPrograms),
    collegeYears: () => instance.get(endpoints.masterData.students.collegeYears),
    searchByTerm: (term: string) => instance.get(endpoints.masterData.students.searchByTerm + '?term=' + term),
    uniqueFee: (form: Record<string, string>) => instance.post(endpoints.masterData.students.uniqueFeeAssign, form),
    enrolledSemestersByStudent: (studentId: string | number) =>
        instance.get(
            endpoints.masterData.students.enrolledSemestersByStudent.replace('{studentId}', studentId?.toString()),
        ),
    completedTrainingProgramByStudent: (studentId: string | number) =>
        instance.get(
            endpoints.masterData.students.completedTrainingProgramByStudent.replace(
                '{studentId}',
                studentId?.toString(),
            ),
        ),
    certificateInstituteSearch: (term: string) =>
        instance.get(endpoints.masterData.students.certification.institute + '?term=' + term),
    instituteSearch: (term: string) =>
        instance.get(endpoints.masterData.students.certification.institutes + '?term=' + term),
    foreignUniversySearch: (name: string) =>
        instance.get(endpoints.masterData.students.actualUniversityStudies.foreignUniversity.list + '?name=' + name),

    /*-- Részletes nézet export --*/
    initiateExport: (form: { format: string }, filter: IStudentsForm) =>
        instance.post(endpoints.masterData.students.export.detailed.initiate, form, {
            params: fixDateRangeFields<string | boolean | IEntityBase>(filter),
        }),
    checkExport: (id: number) =>
        instance.get(endpoints.masterData.students.export.detailed.check.replace('{id}', id.toString())),
    downloadExport: (id: number) =>
        instance.get(endpoints.masterData.students.export.detailed.download.replace('{id}', id.toString()), {
            responseType: 'arraybuffer',
        }),
    /**-- Hallgatói folyamatok MCCENROLLMENT-ből --*/
    ongoingProcesses: (id: string) =>
        instance.get(endpoints.masterData.students.processes.ongoing.replace('{id}', id.toString())),
    doneProcesses: (id: string) =>
        instance.get(endpoints.masterData.students.processes.done.replace('{id}', id.toString())),
    getEnrollment: (id: number) =>
        instance.get(endpoints.masterData.students.processes.getEnrollment.replace('{id}', id.toString())),
    getStudentAllocationPreference: (id: number) =>
        instance.get(
            endpoints.masterData.students.processes.getStudentAllocationPreference.replace('{id}', id.toString()),
        ),
    getRelocation: (id: number) =>
        instance.get(endpoints.masterData.students.processes.getRelocation.replace('{id}', id.toString())),
    getTermination: (id: number) =>
        instance.get(endpoints.masterData.students.processes.getTermination.replace('{id}', id.toString())),
    getMoveOut: (id: number) =>
        instance.get(endpoints.masterData.students.processes.getMoveOut.replace('{id}', id.toString())),
    runCommand1: () => instance.get(endpoints.runCommand.command1),
};

export default studentsService;
