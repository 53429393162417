import CssBaseline from '@mui/material/CssBaseline';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { ThemeProvider as ThemeProviderFromStyles } from '@mui/styles';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { BrowserHistory } from 'history';
import { Suspense, useEffect, useLayoutEffect, useState } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { useDispatch } from 'react-redux';
import { Router } from 'react-router-dom';
import api from './api';
import './App.css';
import MCCLoading from './components/MCCLoading';
import Notification from './components/Notification';
import UserNotificationsRenderer from './components/UserNotifications/Renderer';
import Versions from './components/Versions';
import PrivacyStatementDialog from './pages/Administration/Pages/PrivacyStatementDialog';
import Routing from './routing/Routing';
import pagesService from './services/administration/pages';
import systemMessagesService from './services/systemMessages';
import { history } from './store';
import { setAccessTree } from './store/accessManagement';
import { setActivePrivacyStatement, setUser } from './store/auth';
import { setLastBackendRequestTime } from './utils/AuthHelper';
import { endpoints } from './utils/endPoints';
import { IPrivacyStatement } from './utils/Interfaces/interfaces';
import { theme } from './utils/theme';
import { axiosErrorRedirect } from './utils/AppConst';
import { fetchLocalData } from './utils/timezoneConverter';
import { TenantContextProvider } from './components/TenantContext';

function App() {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState<boolean>(true);
    const [tenant, setTenant] = useState<string>('');
    useEffect(() => {
        const regex = /\/felveteli\/(?=.*[a-zA-Z0-9]).{10,}$/;
        sessionStorage.clear();
        setLastBackendRequestTime(0);
        if (window.location.pathname === '/felveteli' || regex.test(window.location.pathname)) {
            setLoading(false);
            return;
        } else {
            setLoading(true);
            axios
                .all([
                    api.get(endpoints.accessTree),
                    api.get(endpoints.me),
                    systemMessagesService.setUnseenNotifications(),
                ])
                .then((res) => {
                    dispatch(setAccessTree(res[0].data));
                    dispatch(setUser(res[1].data));
                    pagesService
                        .getActivePrivacyStatement()
                        .then((response: AxiosResponse<IPrivacyStatement>) => {
                            dispatch(setActivePrivacyStatement(response.data));
                        })
                        .finally(() => setLoading(false));
                })
                .catch((error: AxiosError) => {
                    axiosErrorRedirect(error);
                });
        }
    }, [dispatch]);

    useEffect(() => {
        fetchLocalData().then((result) => {
            if (result.timezone.offset) {
                localStorage.setItem('offset', result.timezone.offset);
            }
            if (result.tenant) {
                setTenant(result.tenant);
            }
        });
    }, []);

    return (
        <StyledEngineProvider injectFirst>
            <TenantContextProvider tenant={tenant}>
                <ThemeProvider theme={theme}>
                    <ThemeProviderFromStyles theme={theme}>
                        <CssBaseline />
                        <Suspense fallback={<MCCLoading />}>
                            <HelmetProvider>
                                <Notification />
                                <CustomRouter history={history}>
                                    {loading ? (
                                        <MCCLoading />
                                    ) : (
                                        <>
                                            <Routing />
                                            <UserNotificationsRenderer />
                                        </>
                                    )}
                                </CustomRouter>
                                <PrivacyStatementDialog />
                            </HelmetProvider>
                            <Versions />
                        </Suspense>
                    </ThemeProviderFromStyles>
                </ThemeProvider>
            </TenantContextProvider>
        </StyledEngineProvider>
    );
}

/**
 * https://stackoverflow.com/questions/69871987/react-router-v6-navigate-outside-of-components
 */
interface CustomRouterProps {
    history: BrowserHistory;
    children: React.ReactNode;
}
const CustomRouter = (props: CustomRouterProps) => {
    const { history } = props;
    const [state, setState] = useState({
        action: history.action,
        location: history.location,
    });

    useLayoutEffect(() => history.listen(setState), [history]);

    return <Router {...props} location={state.location} navigationType={state.action} navigator={history} />;
};

export default App;
