import Visibility from '@mui/icons-material/Visibility';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { TableButton } from '@silinfo/front-end-template';
import { useNavigate } from 'react-router-dom';
import { clientEndPoints } from '../../../../utils/clientEndPoints';
import { useStyles } from './utils';

export default function InfoCellRenderer(params: GridRenderCellParams) {
    const classes = useStyles();

    const navigate = useNavigate();
    const onClick = () => navigate(clientEndPoints.admin_email_logs_show.replace(':id', params.row.id.toString()));
    return (
        <TableButton color={'info'} style={{ marginRight: '5px' }} className={classes.eyeIcon} onClick={onClick}>
            <Visibility />
        </TableButton>
    );
}
