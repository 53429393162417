import CloseIcon from '@mui/icons-material/Close';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Pagination from '@mui/material/Pagination';
import { ThemeProvider } from '@mui/material/styles';
import { FormPreview } from '@silinfo/form-builder-2';
import { Loading } from '@silinfo/front-end-template';
import { useCallback, useContext, useEffect, useState } from 'react';
import { dummyAsyncFunction } from '../../../../components/PreviewTemplate/utils';
import SemesterContext from '../../../../components/SemesterSelector/SemesterContextProvider';
import courseEvaluationReportService from '../../../../services/courseManagement/courseEvaluationReport';
import { fileProps } from '../../../../utils/AppConst';
import { theme } from '../../../../utils/theme';
import { ContentProps, CourseQuestionnaireFillsStudent } from './types';

export default function ContentStudent({ id, openState }: ContentProps) {
    const { close } = openState;
    const [data, setData] = useState<{ student: string; fills: CourseQuestionnaireFillsStudent[] }>({
        student: '',
        fills: [],
    });
    const [page, setPage] = useState(1);
    const semester = useContext(SemesterContext);

    const elem = data.fills[page - 1];
    const name = data.fills[page % data.fills.length]?.course;

    const handleChange = useCallback((_: unknown, value: number) => {
        setPage(value);
    }, []);

    useEffect(() => {
        courseEvaluationReportService
            .questionnaireFillsStudents(semester, id)
            .then((response) => setData(response.data));
    }, [id, semester]);

    return (
        <>
            <DialogTitle>
                <Grid container justifyContent="space-between">
                    <Grid item container spacing={2} xs={10}>
                        <Grid item>
                            Kurzusértékelések - {data.student || 'Betöltés...'} - {name || 'Betöltés...'}
                        </Grid>
                        <Grid item>
                            <ThemeProvider
                                theme={{
                                    ...theme,
                                    palette: {
                                        ...theme.palette,
                                        text: {
                                            ...theme.palette.text,
                                            primary: theme.palette.primary.main,
                                        },
                                    },
                                }}
                            >
                                <Pagination
                                    color="primary"
                                    variant="outlined"
                                    count={data.fills.length}
                                    page={page}
                                    onChange={handleChange}
                                />
                            </ThemeProvider>
                        </Grid>
                    </Grid>
                    <Grid item xs={1} textAlign="right">
                        <IconButton color="primary" onClick={close}>
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent>
                {data.fills.length > 0 ? (
                    <Grid container>
                        <Grid item xs={12} style={{ marginTop: '-15px', position: 'relative' }}>
                            {data.fills.map((e, i) =>
                                i === page - 1 ? (
                                    <FormPreview
                                        key={JSON.stringify(e)}
                                        form={elem.form}
                                        name={name}
                                        readonly
                                        formToSet={elem.fill}
                                        setForm={() => console.log(elem)}
                                        onSubmit={dummyAsyncFunction}
                                        notificationSuccess=""
                                        fileProps={fileProps}
                                    />
                                ) : null,
                            )}
                        </Grid>
                    </Grid>
                ) : (
                    <Grid item xs={12}>
                        <Loading noHeight />
                    </Grid>
                )}
            </DialogContent>
        </>
    );
}
