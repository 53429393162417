import { TableEditButton } from '@silinfo/front-end-template';
import { clientEndPoints } from '../../../../utils/clientEndPoints';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';

export function BtnCellRenderer(params: GridRenderCellParams) {
    const isSwitch = params.row.settingKey === 'MUST_ACCEPT_PRIVACY_STATEMENT';
    const { user } = useSelector((state: RootState) => state.auth);

    return isSwitch || user.archive ? (
        '-'
    ) : (
        <>
            <TableEditButton
                tooltip={'Szerkesztés'}
                sx={{ marginRight: '5px' }}
                target={clientEndPoints.admin_settings_form.replace(':settingId', params.row.id.toString())}
            />
        </>
    );
}
