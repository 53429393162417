import DoneAll from '@mui/icons-material/DoneAll';
import Visibility from '@mui/icons-material/Visibility';
import HighlightOff from '@mui/icons-material/HighlightOff';
import Grid from '@mui/material/Grid';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { TableButton } from '@silinfo/front-end-template';
import { useContext, useState } from 'react';
import { clientEndPoints } from '../../../utils/clientEndPoints';
import { ListPageContext, handleClick as baseAction } from './utils';
import CircularProgress from '@mui/material/CircularProgress';
import { useDispatch } from 'react-redux';
import { create } from '../../../store/notification';
import { CourseClosingType } from './types';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import { LoadingButton } from '@mui/lab';
import Tooltip from '@mui/material/Tooltip';
import Done from '@mui/icons-material/Done';
import Close from '@mui/icons-material/Close';

export default function OperationsButton(tc: string, params: GridRenderCellParams) {
    const [loading, setLoading] = useState(false);
    const studentId = params.row.id;
    const { programId, refresh } = useContext(ListPageContext);
    const [isDialogOpen, setIsDialogOpen] = useState<CourseClosingType | null>(null);
    const dispatch = useDispatch();

    const handleClick = (set: CourseClosingType) => async () => {
        setLoading(true);
        try {
            await baseAction(programId, studentId)(set);
            dispatch(create({ type: 'success', message: 'Sikeres mentés!' }));
            setIsDialogOpen(null);
            refresh();
        } catch (error) {
            dispatch(create({ type: 'error', message: 'Hiba történt a művelet végrehajtása közben.' }));
        } finally {
            setLoading(false);
        }
    };

    const handleCompleted = handleClick('completed');
    const handleClosed = handleClick('closed');

    return (
        <>
            <Grid container spacing={1} justifyContent="center">
                <Grid item>
                    <TableButton
                        tooltip="Hallgató részletes adatainak megtekintése"
                        color="info"
                        onClick={() =>
                            window.open(
                                clientEndPoints.md_general_students_edit_detailed.replace(
                                    ':id',
                                    params.row.id.toString(),
                                ),
                                '_blank',
                            )
                        }
                    >
                        <Visibility />
                    </TableButton>
                </Grid>
                <Grid item>
                    <TableButton
                        tooltip="Képzés teljesítése"
                        color="success"
                        disabled={loading}
                        onClick={() => setIsDialogOpen('completed')}
                    >
                        {loading ? <CircularProgress size={24} /> : <DoneAll />}
                    </TableButton>
                </Grid>
                <Grid item>
                    <TableButton
                        tooltip="Képzés zárása - teljesítés nélkül"
                        color="error"
                        disabled={loading}
                        onClick={() => setIsDialogOpen('closed')}
                    >
                        {loading ? <CircularProgress size={24} /> : <HighlightOff />}
                    </TableButton>
                </Grid>
            </Grid>
            <Dialog
                open={!!isDialogOpen}
                onClose={() => setIsDialogOpen(null)}
                maxWidth="md"
                fullWidth
                transitionDuration={0}
            >
                <DialogTitle>{isDialogOpen === 'completed' ? 'Képzés teljesítése' : 'Képzés zárása'}</DialogTitle>
                <DialogContent style={{ paddingTop: 16 }}>
                    <DialogContentText>
                        Biztosan {isDialogOpen === 'completed' ? 'teljesítettnek' : 'nem teljesítettnek'} jelölöd a {tc}
                        -t {params.row?.student} hallgatónál, később nem lehet visszavonni?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <LoadingButton variant="outlined" loading={loading} onClick={() => setIsDialogOpen(null)}>
                        Mégsem
                    </LoadingButton>
                    <LoadingButton
                        variant="contained"
                        loading={loading}
                        onClick={isDialogOpen === 'completed' ? handleCompleted : handleClosed}
                    >
                        {isDialogOpen === 'completed' ? (
                            <Tooltip title={'Igen'} placement="top">
                                <Done color={'success'} />
                            </Tooltip>
                        ) : (
                            <Tooltip title={'Igen'} placement="top">
                                <Close color={'error'} />
                            </Tooltip>
                        )}
                        Igen, <b>{isDialogOpen === 'completed' ? 'teljesítettnek ' : 'nem teljesítettnek '}</b>
                        &nbsp;jelölöm
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </>
    );
}
